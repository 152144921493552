<template>
    <div class=" comon-list martDetail">
        <h2 style="margin-bottom: 20px;">结算单详情</h2>
        <div class="comon-publish " >
            <a-button type="primary" @click="addGoods">  添加商品  </a-button>
            </div>
        <div class="comon-check-form ">
            <a-form style="padding: 20px" class="searchArea" :form="form" layout="inline" :label-col="{ span: 7 }"
                :wrapper-col="{ span: 17 }">
                <a-form-item label="商品名称" style="width: 235px">
                    <a-input v-decorator="['title']" placeholder="请输入" />
                </a-form-item>
                <a-form-item label="商品分类" style="width: 235px">
                    <a-cascader v-decorator="[
                        'cateArr',
                        {
                            initialValue: [''],

                        },
                    ]" placeholder="请选择或输入搜索" allowClear 
                        :field-names="{ label: 'name', value: 'code', children: 'items' }" :options="cateList" />
                </a-form-item>
                <a-form-item label="商品编码" style="width: 235px">
                    <a-input v-decorator="['sku']" placeholder="请输入" />
                </a-form-item>
                <a-form-item label="排品逻辑" style="width: 235px">
                    <a-select v-decorator="[
                        'new_type',
                        {
                            initialValue: '',

                        },
                    ]" placeholder="请选择" allowClear>
                        <a-select-option v-for="item in typeList" :key="item.value" :value="item.value">{{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" @click="searchMart"> 搜索 </a-button>
                </a-form-item>
                <a-form-item>
                    <a-button class="resetBtn" @click="resetSearch"> 重置 </a-button>
                </a-form-item>
            </a-form>
        </div>
            <div style="maxHeight: 750px; margin-top: 30px">
                <a-table id="mytable" :pagination="false" :columns="goodsColumns" :scroll="{ x: 700, y: 500 }"
                    :data-source="goodsData"  :loading="loading" @change="onChangTable">
                    <template slot="operation" slot-scope="text, record">
                        <a  href="javascript:;" class="operate grey" @click="() => onEdit(record, 'edit')">修改价格</a>
                        <a-popconfirm  title="确定删除此拍品吗？" ok-text="确定"
                            cancel-text="取消" @confirm="() => onEdit(record, 'delete')">
                            <a href="javascript:;" class="operate">删除</a>
                        </a-popconfirm>
                    </template>
                </a-table>
                <div class="footer">
                    <a-button @click="handleBack" class=" footerBtn"> 返回</a-button>
                    <a-button  type="primary" @click="handleAdd" class=" footerBtn"> 继续添加</a-button>
                </div>
            </div>
            <a-modal title="更改价格" :visible="moduleVisible" okText="确定" cancelText="取消" @ok="handleSubmit"
                @cancel="moduleVisible = false">
                <a-form style="padding: 20px" class="searchArea" :form="editform" :label-col="{ span: 10 }"
                    :wrapper-col="{ span: 14 }">
                    <a-form-item label="名称" style="width: 360px">
                    <a-input disabled v-decorator="['name', { initialValue: activeItem.name }]" placeholder="请输入" />
                    </a-form-item>
                    <a-form-item label="价格" style="width: 360px">
                    <a-input  v-decorator="['sellte_price', { initialValue: activeItem.sellte_price }]" placeholder="请输入" />
                    </a-form-item>
                   
                </a-form>
                </a-modal>
                <a-modal class="preview" :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </div>
</template>



<script>
import {
    h
} from "vue";
import { goodsApi } from "@/api/good";
import {
    message
} from "ant-design-vue";
export default {
    name: "martDetail",
    data() {
        return {
            previewImage: '',
            moduleVisible:false,
            cateList: [],
            activeItem:{},
            typeList: [

                {
                    name: '福利款',
                    value: '1'
                },
                {
                    name: '承接款',
                    value: '2'
                },
                {
                    name: '利润款',
                    value: '3'
                },
                {
                    name: '精品款',
                    value: '4'
                },
                {
                    name: '挂链款',
                    value: '5'
                },
            ],
            goodsData: [],
            goodsColumns: [
                {
                title: "序号",
                dataIndex: "index",
                width: '70px',
                customRender: (text, record, index) => `${index + 1}`,
            },
            {
                title: "商品名称",
                dataIndex: "name",
                width: '150px',
                scopedSlots: {
                    customRender: "name",
                },
            },
            {
                title: "商品编码",
                dataIndex: "sku",
                width: '150px',
                scopedSlots: {
                    customRender: "sku",
                },
            },
            {
                title: "图片",
                dataIndex: "img",
                width: '90px',
                scopedSlots: {
                    customRender: "img",
                },
                customRender: (value, record) => {
                    if (value && value.includes('[')) {
                    let obj = JSON.parse(value);
                    const array = [];

                    obj.map((img, index) => {
                        if (img && index === 0) {
                        array.push(
                            h(
                            "img", {
                            style: {
                                width: "70px",
                                height: "70px",
                                marginLeft: "5px",
                            },
                            attrs: {
                                src: img.url,
                            },
                            on: {
                                click: () => this.picPreview(img.url)
                            }
                            },
                            () => item
                            )
                        );

                        }

                    });
                    return h("div", array);

                    } else {
                    return '--'
                    }
                },
            },
            {
                title: "结算价格",
                dataIndex: "sellte_price",
                width: '150px',
                scopedSlots: {
                    customRender: "sellte_price",
                },
            },
            {
                title: "创建时间",
                dataIndex: "update_date",
                width: '150px',
                scopedSlots: {
                    customRender: "update_date",
                },
            },
            {
            title: "拍品分类",
            dataIndex: "cate_name",
            width: '120px',
            scopedSlots: {
              customRender: "cate_name",
            },
          },
          {
                title: "排品逻辑",
                dataIndex: "new_type",
                scopedSlots: {
                    customRender: "new_type",
                },
                customRender: (value, record) => {
                    let text = this.typeList.filter(item => item.value == value)[0]?.name || ''
                    return text
                }
            },
           
            {
                title: "操作",
                dataIndex: "operation",
                width: '170px',
                fixed: 'right',
                scopedSlots: {
                    customRender: "operation",
                },
            },
            ],
            isLoading: false,
            loading: false,
            page: 1,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 400
            },
           
            settlement_id: this.$route.query.id,
            supplier_id: this.$route.query.supplier_id,
            sourceObj: '',// 源目标数据序号
            sourceIndex: '',
            targetIndex: '',
            targetObj: '',// 目标数据序号
        previewVisible: false,

        };
    },



    beforeCreate() {
        if (this.$cookie.get('username')) {
            this.form = this.$form.createForm(this, {
                name: "validate_other",
            });
            this.editform = this.$form.createForm(this, {
                name: "validate_edit",
            });
        } else {
            message.warning('请登录')
            this.$router.push({ path: '/login' })
        }
    },
    mounted() {
        this.getCateList();

        this.searchMart()
        // this.scroll()
    },
    methods: {
        // 添加商品
        addGoods() {

        },

         //图片预览
      picPreview(src) {
        if (src) {
          this.previewImage = src
          this.previewVisible = true;
        }
      },
       //关闭预览
       handleCancel() {
        this.previewImage = ''
        this.previewVisible = false;
      },
        //重置搜索条件
        resetSearch() {
            this.form.resetFields();
            this.searchMart();
        },
        getCateList() {
            goodsApi.getcatelistnew()
                .then((res) => {
                    this.cateList = res.data.data;
                });



        },
        //搜索
        searchMart() {
            console.log(this.$route.query, 'this.$route.query')
            this.form.validateFields((err, values) => {
                let params = {
                    ...values,
                    cate_id:values.cateArr[1],
                    settlement_id:this.settlement_id,
                    page: this.pagination.current,
                    pageSize: this.pagination.pageSize,
                };
                this.getTable(params);
            });
        },
        //获取表格数据
        getTable(data) {
            this.loading = true;
            goodsApi.listsettlementdetail(data)
                .then((res) => {
                    console.log(res, "resres");
                    const list = res.data.data.list || res.data.data.data
                    if (res.data.status == 200 ) {
                        this.isLoading = true
                        this.goodsData = []
                        this.pagination.total = +res.data.data.total;
                        this.goodsData = list.map((good, index) => { return { ...good, sorts: index } })
                    } else {
                        message.warning('暂无更多数据信息！')
                        this.isLoading = false
                    }
                })
                .finally((res) => {
                    this.loading = false;
                });
        },
        //翻页
        onChangTable(page) {
            this.pagination.current = page.current;
            this.pagination.pageSize = page.pageSize;
            this.searchMart()
        },
        handleBack() {
            this.$router.push({ path: '/purchase/invoicesList' })

        },
        handleAdd() {
            this.$router.push({ path: '/purchase/goodsSold', query: { settlement_id: this.settlement_id,supplier_id:this.supplier_id }  })

        },
        handleSubmit() {
            this.editform.validateFields((err, values) => {
                console.log(err,values,'err---')
                let data = {
                id:   this.activeItem.id ,
                sellte_price:values.sellte_price,

            }
            goodsApi.editsettlementdetail(data).then(res => {
                if (res.data.status == 200) {
                    message.success('编辑成功')
                } else {
                    message.error(res.data.message)
                }
            }).finally(() => {
                this.moduleVisible = false
this.activeItem = {}
                        this.searchMart()
                    })
            })
           
        },
        
        //删除/编辑价格
        onEdit(item, type) {
            console.log(item,'edit----')
            this.activeItem = item
            let params = {
                id:item.id,
             }
             let msg = ''
            switch(type){
                case 'delete':
                    msg = '删除成功'
                    goodsApi.deletesettlementdetail(params).then(res => {
                        if (res.data.status == 200) {
                            message.success(msg)
                        } else {
                            message.error(res.data.message)
                        }

                    }).finally(() => {
                        this.searchMart()
                    })
                    break;
                    case 'edit':
                      this.moduleVisible = true
                    break;    
            }
           

        },
    },
};
</script>

<style lang="less" scoped>

.martDetail {
    padding: 20px;
    overflow: hidden;

    .comon-check-form {
        margin-bottom: 20px;
    }
    .operate{
        &.disa{
            cursor: not-allowed;
            color: #999;

        }

    }
    

    .footer {
        width: 100%;
        text-align: center;
        margin-top: 16px;

        button {
            margin-right: 16px;
        }
    }
}
</style>