<!--
 * @Descripttion: *
 * @Author: wjj
 * @LastEditors: *
 * @LastEditTime: 2024-10-14 14:01:25
-->
<template>
  <div class="purchase-goods">
    <div class="comon-check-form purchase-check-form">
         <div class="supplier_name">供货商： {{ supplier_name }}</div>
        <a-form :form="form" layout="inline" :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }">
          <a-form-item label="拍品平台编码" style="width: 440px">
             <a-input 
              v-decorator="['name', {rules: [{ required: true, message: '请输入平台编码，示例:GP282H728278' }]}]" 
               placeholder="请输入平台编码，示例:GP282H728278" 
               allow-clear
               @change="onChange"
              />
          </a-form-item>

          <a-form-item  style="width: 440px">
            <a-button type="primary" @click="searchPurchaseGoods"> 搜索 </a-button>
            <a-button style="margin-left: 20px;" @click="() => $router.back()">返回列表</a-button>
          </a-form-item>
        </a-form>
      </div>

      <div class="goods-info" v-if="infoData.title">
        <div class="goods-info-item">
          <span>商品名称：</span> <span>{{ infoData.title}}</span>
        </div>
        <div class="goods-info-item">
          <span>图片：</span>
          <img :src="imgUrl" alt="">
        </div>
        <div class="goods-info-item">
          <span>拿货价：</span> <span>{{ infoData.in_price}}</span>
        </div>
        <div class="goods-info-item">
          <span>供货商：</span> <span>{{ infoData.from_username}}</span>
        </div>
        <div class="goods-info-item">
          <span>拍品分类：</span> <span>{{ infoData.cate_name}}</span>
        </div>
        <div class="goods-info-item">
          <span>内部编码：</span> <span>{{ infoData.sku}}</span>
        </div>
        <div class="goods-info-item">
          <span>平台编码：</span> <span>{{ infoData.code }}</span>
        </div>
        <div class="goods-info-item  standards-values">
          <span>拍品尺寸：</span> 
          <ul>
            <li v-for="(item, index) in infoData.standards_values" :key="index">
             <span class="info-label">{{ item.key }}</span> <span>{{ item.value }}</span>
            </li>
          </ul>
        </div>
        <div class="goods-info-item">
          <span>备注：</span>
          <a-textarea
            style="width: 300px;" :rows="4" :max-length=150 v-model="remark" />
        </div>
        <div class="goods-btn">
          <a-button type="primary" @click="addToPurchaseList(infoData)">添加到采购单</a-button>
        </div>
      </div>
  </div>
</template>
<script>
   import { goodsApi } from "@/api/good";
   import { orderApi } from "@/api/order";

export default {
  name: 'PurchaseGoods',
  data() {
    return {
        form: this.$form.createForm(this, { name: 'addPurchaseGoodsForm' }),
        supplier_name: '',
        infoData: {},
        imgUrl: '',
        remark: '',
    }
  },
  mounted() {
    const { supplier_name } = this.$route.query;
    this.supplier_name = supplier_name;
  },
  methods: {
    //清除输入框时清除底部数据
    onChange(e) {
      const { value } = e.target;
      if( !value ) {
        this.infoData = {};
      }

    },

    // 搜索获取数据
    searchPurchaseGoods(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const code = this.form.getFieldValue('name');
          goodsApi.getproductcode({code})
                  .then(res => {
                    // console.log(res.data.data);
                    if (res.data.status == 200) {
                      this.infoData = {...res.data.data};
                      this.imgUrl = JSON.parse(this.infoData.img)[0].url
                    } else {
                      this.infoData = {};
                    }
                  })
                  .catch(err => console.log(err))
            }
      });
    },

    // 添加到采购单
    addToPurchaseList(data) {
      console.log('data--', data)
      const { purchase_id } = this.$route.query;
      const { pid, in_price, client_id } = data;
      const shop_id = this.$cookie.get('shop_id');
      const creator_id = this.$cookie.get('userid');
      const remark = this.remark;
      console.log('remark', remark)
      const params = {
          purchase_id,
          product_id: pid,
          purchase_price: in_price,
          supplier_id: client_id,
          remark,
          creator_id,
          shop_id,
        }
       orderApi.addpurchasedetail(params)
              .then(res => {
                if( res.data.status == 200 ) {
                  this.$message.success('添加成功');
                   this.infoData = {};
                   this.form.setFieldsValue({'name': ''});
                }
                
              })
              .catch(err => console.log(err))
    }
  }
}
</script>

<style lang="less" scoped>
.purchase-goods {
  padding: 20px;
  .supplier_name{
    padding-left: 20px;
    font-weight: 800;
  }
  .goods-info {
    margin-left: 40px;
    .goods-info-item {
      display: flex;
      line-height: 50px;
      > span:nth-child(1) {
        display: inline-block;
        width: 100px;
        margin-right: 20px;
        text-align: right;
        font-weight: bold;
      }
      
      ul {
        padding-left: 100px;
      }

      
    }
    .standards-values {
      display: block;
      line-height: 30px;
      .info-label {
        margin-right: 30px;
      }
    }
    .goods-btn {
      button {
        width: 300px;
        margin: 40px 100px;
      }
    }
  }
}

 
</style>