var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"publish"},[_c('a-form',_vm._b({attrs:{"id":"validate-other","form":_vm.form}},'a-form',_vm.formItemLayout,false),[_c('a-form-item',_vm._b({attrs:{"label":"退货单名称"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          { 
            rules: [{ required: true, message: '请输入' }] 
          },
        ]),expression:"[\n          'name',\n          { \n            rules: [{ required: true, message: '请输入' }] \n          },\n        ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"供货商名称","has-feedback":""}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'supplier_id',
          {
            rules: [
              { required: true, message: '请选择或输入搜索' },
            ],
          },
        ]),expression:"[\n          'supplier_id',\n          {\n            rules: [\n              { required: true, message: '请选择或输入搜索' },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"请选择或输入搜索","allowClear":"","showSearch":""}},_vm._l((_vm.supplierList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.supplier_name)+" ")])}),1)],1),_c('a-form-item',_vm._b({attrs:{"label":"备注信息"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remark',
        ]),expression:"[\n          'remark',\n        ]"}],attrs:{"id":"desc","placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 3 }}},[_c('a-button',{staticClass:"submitBtn footerBtn",attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.submitText))]),_c('a-button',{staticClass:"footerBtn",on:{"click":_vm.handleReset}},[_vm._v(" 重置 ")]),_c('a-button',{staticClass:"footerBtn",on:{"click":_vm.goBackList}},[_vm._v(" 返回列表 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }