
<template>
    <div class="comon-list ">
      <div class="comon-publish ">
      <a-button type="primary" @click="$router.push({ path: '/purchase/newReturn' })">
        新建退货单
      </a-button>
    </div>
      <div class="comon-check-form ">
        <a-form style="padding: 20px" class="searchArea" :form="form" layout="inline" :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }">
          <a-form-item label="退货单名称" style="width: 235px">
            <a-input v-decorator="['title']" placeholder="请输入" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="searchPurchase"> 搜索 </a-button>
          </a-form-item>
          <a-form-item>
            <a-button class="resetBtn" @click="resetSearch"> 重置 </a-button>
          </a-form-item>
        </a-form>
      </div>
      <div style="height: 500px;  margin-top: 30px">
        <a-table 
        :columns="columns" 
        :scroll={x:700,y:500} 
        :data-source="data" 
        :pagination="pagination" 
        :loading="loading"
        @change="onChangTable" 
        >
          <template slot="operation" slot-scope="text, record">
              <a href="javascript:;" @click="() => onDetail(record.pid)" class="operate grey" >查看</a>
          </template>
        </a-table>
      </div>
  
    </div>
  </template>
  
  
  
  <script>
    import {
      h
    } from "vue";
  
    import {orderApi} from "@/api/order"
    import {
      message
    } from "ant-design-vue";
  
    export default {
      name: "PurchaseList",
      data() {
        return {
          data: [],
          columns:[{
            title: "编号",
            dataIndex: "sku",
            width: '150px',
            fixed: 'left',
  
            scopedSlots: {
              customRender: "sku",
            },
          },
          {
            title: "退货单名称",
            dataIndex: "name",
            width: '150px',
            scopedSlots: {
              customRender: "name",
            },
          },
          {
            title: "供货商名称",
            dataIndex: "mid_price",
            width: '90px',
            scopedSlots: {
              customRender: "mid_price",
            },
          },
          {
            title: "数量",
            dataIndex: "cate_name",
            width: '120px',
            scopedSlots: {
              customRender: "cate_name",
            },
          },
        
          {
            title: "总金额",
            dataIndex: "create_time",
            width: '200px',
            scopedSlots: {
              customRender: "create_time",
            },
          },
           {
            title: "创建时间",
            dataIndex: "create_time",
            width: '200px',
            scopedSlots: {
              customRender: "create_time",
            },
          },
           {
            title: "负责人",
            dataIndex: "place",
            width: '200px',
            scopedSlots: {
              customRender: "place",
            },
          },
          {
            title: "状态",
            dataIndex: "place",
            width: '200px',
            scopedSlots: {
              customRender: "place",
            },
          },
  
          {
            title: "操作",
            dataIndex: "operation",
            width: '150px',
            fixed: 'right',
            scopedSlots: {
              customRender: "operation",
            },
          },
        ],
          loading: false,
          pagination: {
            total: 0,
            current: 1,
            pageSize:50
          },
  
          purchaseModal:false,
  
        };
      },
      
      beforeCreate() {
        if(this.$cookie.get('username')){
          this.form = this.$form.createForm(this, {
            name: "validate_other",
          });
        }else{
          message.warning('请登录')
          this.$router.push({path:'/login'})
        }
      },
      mounted() {
        this.searchPurchase();
        message.config({
          top: `100px`,
          duration: 8,
        });
        
      },
      computed: {
          
      },
      methods: {
        //重置搜索条件
        resetSearch () {
          this.form.resetFields();
          this.pagination.current = 1
          this.searchPurchase();
        },
        //获取表格数据
        getTable(data) {
          this.loading = true;
          orderApi.channelproductlist(data)
            .then((res) => {
              console.log(res, "resres");
              if (res.data.status == 200 && res.data.data.list.length > 0) {
                this.data = []
                this.pagination.total = +res.data.data.total;
                
                res.data.data.list.map(good=>{
                   this.data.push({...good,key:good.pid})
                   
                })
              } else {
                this.data = [];
                this.pagination.total = 0;
              }
            })
            .finally((res) => {
              this.loading = false;
            });
        },
        //翻页
        onChangTable(page) {
          this.pagination.current = page.current;
          this.searchPurchase()
        },
        //搜索
        searchPurchase() {
          this.form.validateFields((err, values) => {
            let params = {
              page: this.pagination.current,
              ...values,
            creator_id: this.$cookie.get('userid'),
            shop_id:this.$cookie.get('userid')

            };
            this.getTable(params);
          });
        },
        //查看
        onDetail(pid) {
          this.$router.push({
            path: "/purchase/ReturnableGoodsList",
            query: {
              pid: pid,
            },
          });
        },
        
  
      },
    };
  </script>
  
  <style lang="less" scoped>
   @import "./../../assets/less/reset.less";
  @import "./../../assets/less/config.less";
  .operate{
    padding-right: 5px;
  }
  
   
  </style>