<template>
  <div class="goodsTool">
    <a-form id="validate-other" :form="$form" v-bind="formItemLayout">
      <a-form-item label="批量上传图片">
        <Upload ref="upload" />
      </a-form-item>

      <a-form-item label="批量上传商品">
        <a-row :gutter="8">
          <a-col :span="6">
            <a-upload name="file" :multiple="false" :action="uploadUrl" :headers="uploadHeaders"
              :before-upload="beforeUpload">
              <a-button> <a-icon type="upload" />上传文件</a-button>
            </a-upload>

          </a-col>
          <a-col :span="18">
            <a-button type="primary"> <a :href="downloadUrl" target="_blank">下载模板</a> </a-button>

          </a-col>
        </a-row>



      </a-form-item>

      <a-form-item :wrapper-col="{ span: 12, offset: 3 }">
        <a-button type="primary" @click="handleSubmit" class="submitBtn footerBtn"> 提交</a-button>


      </a-form-item>

    </a-form>
    <a-divider>
      查看上传结果
    </a-divider>
    <div style="text-align: right;margin-bottom: 20px;"><a-button @click="getTable" class="submitBtn "> 查询结果</a-button>
    </div>

    <div>
      <a-table :loading="loading" :columns="columns" :scroll={x:600,y:500} :data-source="tableData" :pagination="false">
        <template slot="operation" slot-scope="text, record">
          <a href="javascript:;" class="operate" @click="() => download(record.file_url)">下载</a>

        </template>

      </a-table>

    </div>

  </div>
</template>
  
<script>
import Upload from "@/components/UploadImg.vue";
import publicConst from "@/utils/public_const";
import { message, Modal } from "ant-design-vue";
import { goodsApi } from "@/api/good"

import {
  h
} from "vue";

export default {
  name: "goodsTool",
  props: {
  },
  data: () => ({
    loading: false,
    tableData: [
    ],
    columns: [
      {
        title: "序号",
        dataIndex: "index",
        width: '70px',
        customRender: (text, record, index) => `${index + 1}`,

      },
      {
        title: "文件名称",
        dataIndex: "table_name",
        width: '180px'
      },
      {
        title: "状态",
        dataIndex: "status",
        width: '100px',
        scopedSlots: {
          customRender: "status",

        },
        customRender: (value, record) => {
          let data = {}
          let text = ''
          if (value == 1) {
            data.style = { color: 'blue' }
            text = '上传中'

          } else if (value == 2) {
            data.style = { color: 'green' }
            text = '已完成'

          }
          return h('div', data, text)
        },
      },
      {
        title: "总条数",
        dataIndex: "total",
        width: '100px'
      },
      {
        title: "成功条数",
        dataIndex: "success_num",
        width: '150px'
      },
      {
        title: "失败条数",
        dataIndex: "fail_num",
        width: '150px'
      },
      {
        title: "上传时间",
        dataIndex: "create_time",
        width: '150px'
      },
      {
        title: "更新时间",
        dataIndex: "update_time",
        width: '150px'
      },
      {
        title: "操作",
        dataIndex: "operation",
        width: '70px',
        fixed: 'right',
        scopedSlots: {
          customRender: "operation",
        },
      },
    ],
    formItemLayout: {
      labelCol: { span: 3 },
      wrapperCol: { span: 21 },
    },
    uploadHeaders: {
      'Content-Type': 'multipart/form-data;'
    },
    uploadUrl: publicConst.proxyPath + '/site/uploadoss',
    downloadUrl: publicConst.proxyPath + '/site/exceldownload',
    file: ''
  }),
  components: {
    Upload,
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "validate_other" });
  },
  created() {

  },
  mounted() {
    this.getTable()
  },
  methods: {
    download(name) {
      let params = {
        filename: name
      }
      goodsApi.downloadfile(params).then((res) => {
          console.log(res, "resres");
          if (res.status == 200) {
            const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = name + '.xlsx';
            link.click();
            message.success('下载成功')
          } else {
            message.error(res.data.message)
          }
        })
        .finally((res) => {
        });

    },
    getTable() {

      let params = {
      }
      this.loading = true
      goodsApi.uploadloglist(params).then((res) => {
          console.log(res, "resres");
          if (res.data.status == 200) {
            this.tableData = res.data.data
          } else {
            message.error(res.data.message)
          }
        })
        .finally((res) => {
          this.loading = false
        });
    },
    beforeUpload(file) {
      console.log(file, 'fff')
      this.file = file

    },

    handleSubmit(e) {
      e.preventDefault();
      console.log(this.$refs.upload.fileList, 'imgs')
      let objImgs = {}
      this.$refs.upload.fileList.map(item => {
        objImgs[item.name] = item.url + "?" + item.uid
      })
      const formData = new FormData();
      formData.append('image', this.file);
      formData.append('imgurls', JSON.stringify(objImgs));
      formData.append('creator_id', this.$cookie.get('userid'));

      this.form.validateFields((err, values) => {
        if (!err) {
          goodsApi.multiuploadproduct(formData).then((res) => {
              console.log(res, "resres");
              if (res.data.status == 200) {
                message.success('上传成功')

              } else {
                // message.error(res.data.message)
                this.$error({
                  title: '提交失败，错误信息',
                  content: res.data.message,
                  okText: '关闭'
                });

              }
            })
            .finally((res) => {
            });




        } else {
          console.log(err)
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
#components-form-demo-validate-other .dropbox {
  height: 180px;
  line-height: 1.5;
}

.ant-modal-confirm-content {
  max-height: 360px;
  overflow-y: auto;

}

.footerBtn {
  width: 120px;
  height: 42px;
  margin-top: 30px;
  margin-left: 74px;
}

.submitBtn {
  margin-left: 0px;
}

.goodsTool {
  padding: 0 30px;
  // width: 1200px;

  #desc {
    resize: none;
    width: 600px;
    height: 60px;
    line-height: 20px;
    padding: 5px 5px;
    overflow-y: scroll;
  }

  :global(.ant-form .ant-form-item) {
    margin-bottom: 10px;
  }
}
</style>
  