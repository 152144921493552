
<template>
    <div class="comon-list">
      <h2 style="margin-bottom: 20px;">退货单详情</h2>
       <div class="comon-publish ">
        <a-button type="primary" @click="addPurchaseGood">  添加商品  </a-button>
      </div>
      <div class="comon-check-form">
        <a-form style="padding: 20px" class="searchArea" :form="form" layout="inline" :label-col="{ span: 7 }"
          :wrapper-col="{ span: 17 }">
          <a-form-item label="拍品名称" style="width: 360px">
          <a-input allowClear v-decorator="['product_name']" placeholder="根据名称、平台或内部编码搜索" />
        </a-form-item>
        <a-form-item label="状态" style="width: 235px">
          <a-select allowClear showSearch v-decorator="['status']" placeholder="请选择或输入搜索">
            <a-select-option v-for="item in stateList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <!-- <a-form-item label="直播间" style="width: 235px">
          <a-select allowClear v-decorator="['live_room_id']"  placeholder="请选择">
            <a-select-option v-for="item in liveRoomList" :key="item.live_room_id" :value="item.live_room_id">{{ item.live_room_name
            }}</a-select-option>
          </a-select>
        </a-form-item> -->
  
          <a-form-item>
            <a-button type="primary" @click="searchGoods"> 搜索 </a-button>
          </a-form-item>
          <a-form-item>
            <a-button class="resetBtn" @click="resetSearch"> 重置 </a-button>
          </a-form-item>
        </a-form>
      </div>
  
      <div style=" position: relative; margin-top: 30px">
        <a-table :columns="columns" :scroll={x:700,y:500} :data-source=" data " :pagination=" pagination "
          :loading=" loading " @change=" onChangTable ">
          <template slot="operation" slot-scope="text, record">
            <a-popconfirm v-if="queryData.tatus != 1" title="确定要删除该商品" ok-text="删除" cancel-text="取消" @confirm="onEdit(record,'delete')">
                <a href="javascript:;" class="operate" >删除</a>
            </a-popconfirm>
            
            <a href="javascript:;" class="operate" @click=" () => onEdit(record,'check') ">查看</a>
          </template>
        </a-table>
        <div class="footer">
          <a-button type="primary" @click="handleBack" class=" footerBtn"> 返回</a-button>
      </div>
      </div>
     
  
    </div>
  </template>
   
  <script>
  import {orderApi} from "@/api/order"
  import { goodsApi } from "@/api/good";
  import { h } from "vue";

    import { message  } from "ant-design-vue";
  
  export default {
    name: "GoodsList",
    data() {
      return {
        // 页面带过来的数据
        queryData: { },
        data: [],
        columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: '70px',
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "商品名称",
          dataIndex: "name",
          key: "name",
          width: '200px',
          // fixed: 'left',
          scopedSlots: {
            customRender: "name",
          },
        },
        {
          title: "图片",
          dataIndex: "img",
          key: 'img',
          width: '140px',
          scopedSlots: {
            customRender: "img",
          },
          customRender: (value, record) => {
            if (value && value.includes('[')) {
              let obj = JSON.parse(value);
              const array = [];
    
             obj.map((img, index) => {
                if (img && index === 0) {
                  array.push(
                    h(
                      "img", {
                      style: {
                        width: "70px",
                        height: "70px",
                        marginLeft: "5px",
                      },
                      attrs: {
                        src: img.url,
                      },
                      on: {
                        click: () => this.picPreview(img.url)
                      }
                    },
                      () => item
                    )
                  );

                }

              });

              return h("div", array);

            } else {
              return '--'
            }
          },
        },
        {
          title: "平台编码",
          dataIndex: "sku",
          key: "sku",
          width: '150px',
          scopedSlots: {
            customRender: "sku",
          },
        },
         {
          title: "代发价",
          dataIndex: "sale_price",
          key: "sale_price",
          width: '90px',
          scopedSlots: {
            customRender: "sale_price",
          },
          customRender: (value, record) => {
            return parseInt(value)
          }
        },
        {
          title: "拿货价",
          dataIndex: "retail_price",
          key: "retail_price",
          width: '90px',
          scopedSlots: {
            customRender: "retail_price",
          },
          customRender: (value, record) => {
            return parseInt(value)
          }
        },
        {
          title: "备注信息",
          dataIndex: "remark",
          key: "remark",
          width: '90px',
          scopedSlots: {
            customRender: "remark",
          },
        },
        {
          title: "退货时间",
          dataIndex: "refund_time",
          key: "refund_time",
          width: '120px',
          scopedSlots: {
            customRender: "refund_time",
          },
        },
        {
        title: "商品状态",
        dataIndex: "refund_status",
        key: "refund_status",
        width: '200px',
        scopedSlots: {
          customRender: "refund_status",
        },
        customRender: (value, record) => {
          // 1-未售出、2-已售出、3-已退货、4-直播销售中

          if (value == 1) {
              return '未售出'
          } else if (value == 2) {
                return '已售出、3'
          }else if (value == 3) {
                return '已退货'
          } else if (value == 4) {
            return '直播销售中';
          }

          return '--'

        }
      },
  
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          width: '150px',
          // fixed: 'right',
          scopedSlots: {
            customRender: "operation",
          },
        },
        ],
        selectedRowKeys: [],
        loading: false,
        pagination: {
          total: 0,
          current: 1,
          pageSize: 50,
        },
        typeList: [
          {
            name: '全部',
            value: '0'
          },
          {
            name: '生意货',
            value: '1'
          },
          {
            name: '臻品',
            value: '2'
          },
        ],
        cateList: [],
        stateList: [ // 状态列表
          {name:'未售出',id:'1'},
          {name:'已售出',id:'2'},
          {name:'已退货',id:'3'},
          {name:'直播间销售中',id:'4'}
        ],
        fromUsernameList: [],
        toUsernameList: [],
        goodStatus: 1,
        previewVisible: false,
        previewImage: "",
        moduleVisible:false,
        returnModuleVisible:false,
        modalType:'check'
      };
    },
  
    beforeCreate() {
      if (this.$cookie.get('username')) {
        this.form = this.$form.createForm(this, {
          name: "validate_other",
        });
      } else {
        message.warning('请登录')
        this.$router.push({ path: '/login' })
      }
    },
    mounted() {
      const { status, refund_id } = this.$route.query;
      this.queryData = {
        status, 
        refund_id
      }

      this.getCateList();
      this.searchGoods();
      message.config({
        top: `100px`,
        duration: 8,
      });
    },
    computed: {
      hasSelected() {
        return this.selectedRowKeys.length > 0;
      },
    },
    methods: {
      handleBack() {
           this.$router.back()
        },

        // 添加退货商品
        addPurchaseGood() {
          this.$router.push({
            path: '/purchase/ReturnableGood',
            query: {
              refund_id:this.$route.query.refund_id,
            }
          })
        },
  
      //重置搜索条件
      resetSearch() {
        this.form.resetFields();
        this.pagination.current = 1
        this.searchGoods();
      },
      //图片预览
      picPreview(src) {
        if (src) {
          this.previewImage = src
          this.previewVisible = true;
        }
      },
      //关闭预览
      handleCancel() {
        this.previewImage = ''
        this.previewVisible = false;
      },
      //分类-行制-委托人列表
      getCateList() {
       goodsApi.getcatelist()
          .then((res) => {
            this.cateList = res.data.data;
          });
        goodsApi.gettofromlist().then(res => {
          this.fromUsernameList = res.data.data.clientsList
          this.toUsernameList = res.data.data.receiverList
        })
      },
      //获取表格数据
      getTable(data) {
        this.loading = true;
        orderApi.listrefunddetail(data)
          .then((res) => {
            console.log(res, "resres");
            if (res.data.status == 200 && res.data.data.list.length > 0) {
              this.data = []
              this.selectedRowKeys = []
              this.pagination.total = +res.data.data.total;
  
              res.data.data.list.map((good, index) => {
                this.data.push({ ...good, key: index })
  
              })
            } else {
              this.data = [];
              this.pagination.total = 0;
            }
          })
          .finally((res) => {
            this.loading = false;
          });
      },
      //翻页
      onChangTable(page) {
        this.pagination.current = page.current;
        this.searchGoods()
      },
      changStatus() {
        this.resetSearch()
      },
      changefromUsername(value) {
        let pid = this.fromUsernameList.filter(item => item.name == value)[0].pid
        let hasTo = this.toUsernameList.filter(item => item.id == pid)[0]
        let name = ''
        if (hasTo) {
          name = hasTo.name
        }
        this.form.setFieldsValue({ to_username: name })
      },
      //搜索
      searchGoods() {
        this.form.validateFields((err, values) => {
          let params = {
            page: this.pagination.current,
            pageSize: 50,
            refund_id:this.$route.query.refund_id,
            ...values,
            creator_id: this.$cookie.get('userid'),
            shop_id:this.$cookie.get('userid')

          };
          this.getTable(params);
        });
      },
      //编辑
      onEdit(item,type) {
        let params ={
          creator_id: this.$cookie.get('userid'),
            shop_id:this.$cookie.get('userid')
        }
        switch(type){
          case 'delete':
            let data = {
              refund_detail_id:item.refund_detail_id,...params
            }
          orderApi.deleterefunddetail(data)
          .then((res) => {
            if (res.data.status == 200) {
              message.success(res.data.message);
              this.searchGoods();
            }
          });
  
            break;
            case 'check':
            this.$router.push({ path: '/goods/publish',query: {
              pid :item.product_id,
              purchase_id: '',
              ...params
            } })
            break;
            default:
            break;
  
        }
        
       
      },
      
  
      handleChange(value, key, column) {
        const newData = [...this.data];
        const target = newData.find((item) => key === item.key);
        if (target) {
          target[column] = value;
          this.data = newData;
        }
      },
  
      onSelectChange(selectedRowKeys) {
        console.log("selectedRowKeys changed: ", selectedRowKeys);
        this.selectedRowKeys = selectedRowKeys;
      },
    },
  };
  </script>
    
  <style lang="less" scoped>
    .footer {
      position: absolute;
      bottom: 15px;
      text-align: left;

        button {
            margin-right: 16px;
        }
    }
</style>