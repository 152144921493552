
<template>
  <div class="comon-list purchase-list">
    <div class="comon-publish ">
      <a-button type="primary" @click="() => onEdit('', 'add')">
        新建结算单
      </a-button>
    </div>
    <div class="comon-check-form purchase-check-form">
        <a-form style="padding: 20px" class="searchArea" :form="form" layout="inline" :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }">
          <a-form-item label="结算单名称" style="width: 280px">
            <a-input v-decorator="['name']" placeholder="请输入" />
          </a-form-item>

          <a-form-item label="供货方" style="width: 235px">
            <a-select  v-decorator="['supplier_id']"
              placeholder="请选择">
              <a-select-option v-for="item in supplierList" :key="item.id" :value="item.id">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="起止时间" style="width: 360px">
            <a-range-picker :locale="locale" v-decorator="[ 'range-picker']"  @change="onChange"/>
          </a-form-item>

          <a-form-item>
            <a-button type="primary" @click="searchData"> 搜索 </a-button>
          </a-form-item>
          <a-form-item>
            <a-button class="resetBtn" @click="resetSearch"> 重置 </a-button>
          </a-form-item>
        </a-form>
      </div>

    <div style="height: 500px;  margin-top: 30px">
      <a-table :columns="columns" :scroll={x:700,y:500} :data-source="data" :pagination="pagination" :loading="loading"
        @change="onChangTable">
        <template slot="operation" slot-scope="text, record">
          <a href="javascript:;" class="operate"  @click="() => onEdit(record, 'into')"> 查看</a>

            <a href="javascript:;"  v-if="record.status == 0" class="operate"  @click="() => onEdit(record, 'edit')">编辑</a>

            <a-popconfirm v-if="record.status == 0" title=" 确定提交审核此结算单吗？
            " ok-text="确定" cancel-text="取消" @confirm="() => onEdit(record, 'audit')">
            <a href="javascript:;" class="operate">  提交审核</a>
          </a-popconfirm>

          <a-popconfirm 
             v-if="record.status == 0"
              title=" 确定删除此结算单吗" 
              ok-text="确定" 
              cancel-text="取消" 
              @confirm="() => onEdit(record, 'delete')"
            >
              <a href="javascript:;" class="operate">删除</a>
          </a-popconfirm>

          
          <!-- <a-popconfirm v-if="record.status != 4" title=" 确定关闭此结算单吗？
            " ok-text="确定" cancel-text="取消" @confirm="() => onEdit(record, 'close')">
            <a href="javascript:;" class="operate">
              关闭</a>
          </a-popconfirm> -->
        </template>
      </a-table>
    </div>
    <a-modal :title="editTitle" :visible="moduleVisible" okText="确定" cancelText="取消" @ok="handleOk"
      @cancel="moduleVisible = false">
      <a-form style="padding: 20px" class="searchArea" :form="editform" :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }">
        <a-form-item label="结算单名称" style="width: 360px">
          <a-input 
            v-decorator="['name', { initialValue: activeItem.name }]" 
            placeholder="请输入" 
            :disabled="editType === 'edit'"
          />
        </a-form-item>
        <a-form-item label="供应商ID" style="width: 360px">
          <a-select allowClear 
            v-decorator="['supplier_id', { initialValue: activeItem.supplier_id }]" 
            placeholder="请选择"
            :disabled="editType === 'edit'"
          >
            <a-select-option v-for=" item  in  supplierList " :key="item.id" :value="item.id">{{ item.supplier_name }}</a-select-option>
        </a-select>
        </a-form-item>
          <a-form-item label="备注" style="width: 360px">
              <a-textarea 
                v-decorator="['remark', { initialValue: activeItem.remark }]"
                placeholder="请输入"    
                style="width: 300px;" 
                :rows="4" :max-length=150 
              />
              <!-- <a-textarea  v-decorator="['remark', { initialValue: activeItem.remark }]" placeholder="请输入" /> -->
            </a-form-item>
        </a-form>
    </a-modal>

  </div>
</template>
  
  
  
<script>
import {
  h
} from "vue";

import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';

import publicConst from "@/utils/public_const";
import axios from "axios";
import {
  message
} from "ant-design-vue";
import { goodsApi } from "@/api/good";

export default {
  name: "PurchaseList",
  data() {
    return {
      locale,
      form: this.$form.createForm(this, { name: 'searchPurchaseList' }),
      dateString: {}, // 搜索起止时间
      moduleVisible: false, // 是否打开编辑框
      editTitle: '添加',
      editType: 'add',
      supplierList: [],
      activeItem: {},
      data: [],
      columns: [{
        title: "序号",
        width: '70px',
        dataIndex: "index",
        customRender: (text, record, index) => `${index + 1}`,

      },
      {
        title: "结算单名称",
        dataIndex: "name",
        width: '150px',
        scopedSlots: {
          customRender: "name",
        },
      },
      {
        title: "供应商",
        dataIndex: "supplier_name",
        width: '120px',
        scopedSlots: {
          customRender: "supplier_name",
        },
      },

      {
        title: "商品数量",
        dataIndex: "nums",
        width: '100px',
        scopedSlots: {
          customRender: "nums",
        },
      },
      {
        title: "结算金额",
        dataIndex: "settle_amount",
        width: '120px',
        scopedSlots: {
          customRender: "settle_amount",
        },
      },
      {
        title: "状态",
        dataIndex: "status",
        width: '80px',
        scopedSlots: {
          customRender: "status",
        },
        customRender: (value, record) => {
          // 0未提交； 1已提交
          if (value == 0) {
            return '未提交'
          }
          if (value == 1) {
            return '已提交'
          }
          return '--'
        }
      },
      {
        title: "创建时间",
        dataIndex: "create_date",
        width: '200px',
        scopedSlots: {
          customRender: "create_date",
        },
      },
      {
        title: "备注信息",
        dataIndex: "remark",
        width: '200px',
        scopedSlots: {
          customRender: "remark",
        },
      },
      {
        title: "操作",
        dataIndex: "operation",
        width: '220px',
        fixed: 'right',
        scopedSlots: {
          customRender: "operation",
        },
      },
      ],
      loading: false,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 50
      },


    };
  },

  beforeCreate() {
    if (this.$cookie.get('username')) {
      this.editform = this.$form.createForm(this, {
          name: "validate_edit",
        });
    } else {
      message.warning('请登录')
      this.$router.push({ path: '/login' })
    }
  },
  mounted() {
    this.getsupplierList()
    this.searchData();
    message.config({
      top: `100px`,
      duration: 8,
    });

  },
  computed: {

  },
  methods: {
    // 搜索时间变化操作
     onChange(date, dateString) {
      this.dateString = {
        stime: dateString[0],
        etime: dateString[1]
      };
    },
      //供货商列表
      async getsupplierList(){
      let data = {}
       goodsApi.supplierlist(data).then(res=>{
          this.supplierList = res.data.data
        })
        
      },
    //获取表格数据
    getTable(data) {
      goodsApi.listsettlement(data)
        .then((res) => {
          console.log(res, "resres");
          if (res.data.status == 200 && res.data.data.data.length > 0) {
            this.data = []
            this.pagination.total = +res.data.data.total;

            res.data.data.data.map(good => {
              this.data.push({ ...good, key: good.id })

            })
          } else {
            this.data = [];
            this.pagination.total = 0;
          }
        })
        .finally((res) => {

        });
    },

    //搜索
    searchData() {
      const { name, supplier_id } = this.form.getFieldsValue();
      const { stime, etime} = this.dateString;
      let params = {
        page: this.pagination.current,
        pageSize: this.pagination.pageSize,
        creator_id: this.$cookie.get('userid'),
        shop_id:this.$cookie.get('userid'),
        name,
        supplier_id,
        stime,
        etime,
      };

      this.getTable(params);
    },

    //重置采搜索条件
      resetSearch () {
        console.log('重置采搜索条件')
        this.form.resetFields();
        this.pagination.current = 1;
        this.dateString = {};
        this.searchData();
      },

    //编辑
    onEdit(record, type) {
      this.editType = type
      let url = ''
      let msg = ''
      const { name, id, supplier_id, status, remark} = record;

      let params = { id };
      switch (type) {
        case 'add': // 添加结算单
          this.moduleVisible = true
          this.editTitle = '添加'
          this.activeItem = {}
          break;
        case 'into': // 查看
          this.$router.push({ 
            path: '/purchase/invoicesDetal', 
            query: { 
              id,
              supplier_id,
              status,
            } 
          });
          break;
        case 'edit': // 编辑
          this.moduleVisible = true;
          this.editTitle = '编辑结算单';
  
          this.activeItem = {
            name,
            supplier_id,
            id,
            remark
          };

          // params.status = 3
          break;
        case 'audit': // 提交审核结算
          params.status = 1
          url = 'editsettlement'
          msg = '提交审核结算单成功'
          break;
        // case 'close':
        //   params.status = 4

        //   url = 'editsettlement'
        //   msg = '关闭结算单成功'
        //   break;
        case 'delete': // 删除
          url = 'deletesettlement'
          msg = '删除结算单成功'

          break;
        default:
          break;
      }
    if (!url)  return;

     goodsApi[url](params)
        .then((res) => {
          console.log(res, "res");
          if (res.data.status == 200) {
            message.success(msg)
          } else {
            message.error(res.data.message)
          }
        })
        .finally((res) => {
          this.searchData()

        });
    },

    // 新增/编辑框提交操作
    handleOk() {
      this.editform.validateFields((err, values) => {
        let params = {
          ...values

        }
        let url = ''
        let msg = '';
        switch (this.editType) {
          case 'add':
            url = 'addsettlement';
            msg = '添加结算单成功';
            break;
          case 'edit':
            // url = 'editshop'
            url = 'editsettlement';
            msg = '编辑结算单成功';
            params = {
              ...params,
              status: 0, // 编辑操作写死状态为1
              id: this.activeItem.id
            }
            break;
          default:
            break;
        }
        console.log('url', url)
       if (!url)  return;

        goodsApi[url](params)
          .then((res) => {
            console.log(res, "resres");
            if (res.data.status == 200) {
              message.success(msg)
            } else {
              message.error(res.data.message)
            }
          })
          .finally((res) => {
            this.moduleVisible = false
            this.activeItem ={}
            this.editform.resetFields();

            this.searchData()

          });

      })


    },

     //翻页
    onChangTable(page) {
      this.pagination.current = page.current;
      this.searchData()
    },

  },
};
</script>
  
<style lang="less" scoped>
@import "./../../assets/less/reset.less";
@import "./../../assets/less/config.less";

.operate {
  padding-right: 5px;
}
</style>