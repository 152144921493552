
<template>
    <div class="comon-list purchase-list">
      <div class="comon-publish ">
        <a-button type="primary" @click="() => onEditStatus('','add')">
          新增委托人
        </a-button>
      </div>
      
      <div style="height: 500px;  margin-top: 30px">
        <a-table 
        :columns="columns" 
        :scroll={x:700,y:500} 
        :data-source="data" 
        :pagination="pagination" 
        :loading="loading"
        @change="onChangTable" 
        >
          <template slot="operation" slot-scope="text, record">
            <a-popconfirm v-if="data.length" title=" 确定删除此用户吗？
            " ok-text="确定" cancel-text="取消" @confirm="() => onEditStatus(record,'delet')">
            <a href="javascript:;" class="operate">
             删除</a>
          </a-popconfirm>
          <a href="javascript:;"  @click="() => onEditStatus(record,'edit')" class="operate">
             编辑</a>
          </template>
        </a-table>
      </div>

      <a-modal width="600px" :title="moduleTitle" :visible="moduleVisible" okText="确定" cancelText="取消" @ok="handleOk"
            @cancel="moduleVisible = false">
            <a-form  :form="editform" :label-col="{ span: 7 }"
                :wrapper-col="{ span: 17 }" >
                <a-form-item label="委托人名称" style="width: 400px">
                    <a-input v-decorator="[
                      'name', 
                      {
                        initialValue: activeItem.name,
                        rules: [ 
                        { required: true, message: '请输入委托人名称' },
                        {validator: checkName}
                        ] 
                      }
                    ]"/>
                </a-form-item>
                <a-form-item label="委托人电话" style="width: 400px">
                    <a-input v-decorator="[
                      'tel', 
                      {
                        initialValue: activeItem.tel,
                        rules: [{ validator: validatePhoneNumber}]
                      }
                    
                    ]" />
                </a-form-item>
                 <a-form-item label="账期" style="width: 400px;">
                    <a-input v-decorator="[
                      'pay_due',
                      {
                          initialValue: activeItem.pay_due,
                          rules: [
                          { required: true, message: '请输入账期' },
                          {validator: payDueCheck }
                          ] 
                        }
                      ]" 
                      suffix="天" />

                </a-form-item>
            </a-form>
        </a-modal>
  
    </div>
  </template>
  
  
  
  <script>
    import {
      h
    } from "vue";
  
    import publicConst from "@/utils/public_const";
    import axios from "axios";
    import {
      message
    } from "ant-design-vue";
import { userApi } from "@/api/user";
  
    export default {
      name: "clientManageList",
      data() {
        return {
            activeItem:{},
            moduleVisible: false,
            moduleTitle: '新增委托人',
            editform: 'addform',
            formType: 'add',
            client_id: '',
          data: [],
          columns:[{
            title: "编号",
            width: '70px',
            fixed: 'left',
            dataIndex: "id",
            key: 'id',
            scopedSlots: {
              customRender: "id",
            },
  
          },
          {
            title: "委托人名称",
            dataIndex: "name",
            key: 'name',
            width: '150px',
            scopedSlots: {
              customRender: "name",
            },
          },
          {
            title: "委托人电话",
            dataIndex: "tel",
            key: 'tel',
            width: '90px',
            scopedSlots: {
              customRender: "tel",
            },
          },
          {
            title: "对接人名称",
            dataIndex: "receiver_name",
            key: 'receiver_name',
            width: '120px',
            scopedSlots: {
              customRender: "receiver_name",
            },
          },
          {
            title: "委托人店铺名称",
            dataIndex: "client_shop_name",
            key: 'client_shop_name',
            width: '200px',
            scopedSlots: {
              customRender: "client_shop_name",
            },
          },
          {
            title: "账期(天)",
            dataIndex: "pay_due",
            key: 'pay_due',
            width: '200px',
            scopedSlots: {
              customRender: "pay_due",
            },
           
          },
          {
            title: "对接人店铺名称",
            dataIndex: "receiver_shop_name",
            key: 'receiver_shop_name',
            width: '200px',
            scopedSlots: {
              customRender: "receiver_shop_name",
            },
          },
  
          {
            title: "操作",
            dataIndex: "operation",
            key: 'operation',
            width: '150px',
            fixed: 'right',
            scopedSlots: {
              customRender: "operation",
            },
          },
        ],
          loading: false,
          pagination: {
            total: 0,
            current: 1,
            pageSize:20
          },
  
  
        };
      },
      
      beforeCreate() {
        if(this.$cookie.get('username')){
         
        }else{
          message.warning('请登录')
          this.$router.push({path:'/login'})
        }
      },
      mounted() {
        this.searchPurchase();
        message.config({
          top: `100px`,
          duration: 8,
        });
        
      },
      computed: {
          
      },
      methods: {
        // 委托方名称校验
        checkName(rule, value,callback) {
          if(value && value.length > 30) {
            callback('委托人名称不能大于30个字符')
          } else {
            callback();
          }
        },

        // 手机号校验
        validatePhoneNumber(rule, value, callback) {
          // 中国大陆手机号码正则表达式
          const regex = /^1[3-9]\d{9}$/;
          if(value !=='' && !regex.test(value)) {
             callback('请输入正确的手机号')
          } else {
            callback()
          }
         
        },

        // 账期校验
        payDueCheck(rule, value, callback) {
           if(value && value > 365) {
            callback('账期不能超过365天')
          } else {
            callback();
          }
        },

        //获取表格数据
        getTable(data) {
          userApi.getclientlist(data)
            .then((res) => {
              if (res.data.status == 200 && res.data.data.list.length > 0) {
                this.data = []
                this.pagination.total = +res.data.data.total;
                res.data.data.list.map(good=>{
                   this.data.push({...good,key:good.id})
                   
                })
              } else {
                this.data = [];
                this.pagination.total = 0;
              }
            })
            .finally((res) => {
              
            });
        },

        //翻页
        onChangTable(page) {
          this.pagination.current = page.current;
          this.searchPurchase()
        },
        //搜索
        searchPurchase() {
            let params = {
              name: '',
              shop_id: this.$cookie.get('shop_id'),
              page: this.pagination.current,
              pageSize:this.pagination.pageSize,

            };
            this.getTable(params);
        },

         //改变状态
        onEditStatus(record,type) {
          switch (type){
            case 'delet':
              let data = {
                client_id:record.id

              };
              userApi.clientdelete(data)
                .then((res) => {
                 // console.log(res, "resres");
                  if (res.data.status == 200 ) {
                    message.success(`删除成功`)
                    
                  } else {
                    message.error(`删除失败`)
                  }
                })
                .finally((res) => {
                  this.searchPurchase()
                  
                });
            break;
            case 'add':
              this.editform = this.$form.createForm(this, { name: "editform"});
              this.moduleVisible = true;
              this.moduleTitle = '新增委托人';
              this.formType = 'add';
              this.activeItem = {};
            break;
            case 'edit':
              this.editform = this.$form.createForm(this, {name: "editform" });
                this.moduleVisible = true;
                this.moduleTitle = '编辑委托人';
                this.formType = 'edit';
                const { name, tel, pay_due, id } = record;
                this.client_id = id;
                this.$nextTick(() => {
                  this.editform.setFieldsValue({
                       name, 
                       tel, 
                       pay_due
                  });
                })
                
            break;
            default:
              break;    
          }
          
        
        },
        
        //新增委托人
        addclient(formDate) {
          const { name, tel, pay_due } = formDate ;
          const params = {
              name,
              tel,
              pay_due,
              creator_id: this.$cookie.get('userid'),
              shop_id:this.$cookie.get('userid'),
          }

           userApi.addclient(params)
                .then((res) => {
                   if (res.data.status == 200) {
                      message.success(`新建委托方成功`)

                  } else {
                      message.error(`编辑委托方失败`)
                  }
                })
               .finally((res) => {
                    this.moduleVisible = false
                    this.searchPurchase()

                });
        }, 

        // 编辑委托人
        clientedit(formDate) {
           const { name, tel, pay_due } = formDate ;
            const params = {
                name,
                tel,
                pay_due,
                client_id: this.client_id
            }
            //console.log(params,'params------')
            userApi.clientedit(params)
            .then((res) => {
                   if (res.data.status == 200) {
                      message.success(`编辑成功`)

                  } else {
                      message.error(`编辑失败`)
                  }
                })
               .finally((res) => {
                    this.moduleVisible = false
                    this.searchPurchase()

                });
        },
  
        // 提交
        handleOk(e){
          e.preventDefault();
          this.editform.validateFields((err, values) => {
              if (!err) {
                  if(this.formType === 'add') {
                    this.addclient(this.editform.getFieldsValue());
                  } else {
                   this.clientedit(this.editform.getFieldsValue());
                  }
              
              }
            });
          
         

              
        },
        
  
      },
    };
  </script>
  
  <style lang="less" scoped>
   @import "./../../assets/less/reset.less";
  @import "./../../assets/less/config.less";
  .operate{
    padding-right: 5px;
  }
  </style>