<!--
 * @Descripttion: *
 * @Author: wjj
 * @LastEditors: *
 * @LastEditTime: 2024-10-14 19:13:58
-->
<template>
  <div class="login">
    <div class="login-bg"></div>
    <div class="warp">
      <div class="login-left">
        <div>
          <h2>古玩网络速拍</h2>
          <p>v1.0.0</p>
        </div>
      </div>
      <div class="login-right">
         <div class="login-form">
          <h3>
            <span class="checked">欢迎登录</span>
          </h3>
          <div class="input-item">
            <label for="username">账号</label>
            <div class="input">
              <input type="text" id="username" placeholder="请输入手机号" v-model="username" autocomplete="off"/>
            </div>
          </div>
          <div class="input-item">
            <label for="password">密码</label>
            <div class="input">
              <input
                type="password"
                id="password"
                placeholder="请输入密码"
                v-model="password"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="btn-box" @click="loginSubmit">登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Notify, Icon, Button } from 'vant';
import publicConst  from '@/utils/public_const'
import axios from "axios";
import { message } from 'ant-design-vue';


export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      show: false
    }
  },
  components: {
    // [Notify.Component.name]: Notify.Component,
    // [Icon.name]: Icon,
    // [Button.name]: Button
  },
  methods: {
    loginSubmit(){
      let { username,password } = this;
      if(!username || !password) {
        this.$message.error('请输入手机号和密码');
      }
      console.log('登录成功')

      axios.post( publicConst.proxyPath +  '/user/login',{
        username,
        password
      },{
          headers:{'Content-Type': 'multipart/form-data'}
      }).then((res)=>{
        if(res.data.status == 200 && res.data.data){
          this.saveUserInfo(res.data.data);
          this.$cookie.set('username', username, { expires: 7});
          this.$cookie.set('usernameStr', res.data.data.username, { expires: 7});
          this.$cookie.set('password', password, { expires: 7});
          this.$cookie.set('userid', res.data.data.id, { expires: 7}); //用户id
          this.$cookie.set('shop_id', res.data.data.shop_id, { expires: 7}); //店铺id
          this.$cookie.set('role', res.data.data.role, { expires: 7}); //角色
          this.$cookie.set('role_name', res.data.data.role_name, { expires: 7}); //店铺名称
          const logo_url = JSON.parse(res.data.data.shop_info.logo).url; // logo 
          this.$cookie.set('logo_url', logo_url, { expires: 7}); 
          this.$cookie.set('shop_name', res.data.data.shop_info.name, { expires: 7}); 
          this.$cookie.set('creator_id', res.data.data.shop_info.creator_id, { expires: 7}); 

          this.$router.push({path:'/goods/list'});
          localStorage.setItem('defaultOpenKeys', ['0']);
         localStorage.setItem('defaultSelectedKeys', ['3-1-1']);
          
        }else{
          console.log(res,'res')
          message.error(res.data.message)
        }
      })
    },

    saveUserInfo(userInfo){


    },
  }
}
</script>

<style lang="less" scoped>
@import "./../../assets/less/config.less";

.login {
  width: 100%;
  height: 100%;
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.login {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .login-bg{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("./../../assets/imgs/login-bg.jpeg");
      background-repeat: no-repeat;
      background-size: 1920px 1080px;
      background-position: 50%;
      z-index: 1;
    }
  // background: url("./../assets/imgs/login-bg.jpeg") no-repeat center;
  // background-size: cover;
  // overflow: hidden;

  .warp {
    display: flex;
    margin: 50px auto;
    width: 1200px;
    height: 700px;
    z-index: 2;
  }
  .login-left{
    width: 50%;
    background-color: rgba(83, 129, 214, 0.6);
    height: 100%;
    div {
      padding-top: 30px;
      padding-left: 30px;
      color: #fff;
    }
    h2 {
      font-size: 34px;
      font-weight: 500;
    }
  }
  .login-right {
    width: 50%;
    background: #fff;
    height: 100%;
    .login-form {
        box-sizing: border-box;
        width: 380px;
        height: 500px;
        margin: 100px auto;
        h3 {
          line-height: 36px;
          font-size: 36px;
          margin: 40px auto 49px;
          .checked {
            color: @themeColor;
          }
          .sep-line {
            margin: 0 32px;
          }
        }
        .input-item {
          label {
            font-size: 14px;
          }
        }
        .input {
          display: inline-block;
          width: 100%;
          height: 50px;
          margin-top: 5px;
          margin-bottom: 30px;
          input {
            width: 100%;
            height: 100%;
            border: 1px solid #e5e5e5;
            border-radius: 6px;
            padding: 18px;
          }
        }
        .btn-box {
          width: 100%;
          line-height: 50px;
          text-align: center;
          font-size: 18px;
          color:#fff;
          background: @themeColor;
          border-radius: 6px;
          margin-top: 40px;
          cursor: pointer;
        }
        .btn-box:hover{
          opacity: 0.9;
        }
        .tips {
          margin-top: 14px;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          cursor: pointer;
          .sms {
            color: #ff6600;
          }
          .reg {
            color: #999999;
            span {
              margin: 0 7px;
            }
          }
        }
      }
  }
}
</style>
