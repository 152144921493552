<!--
 * @Descripttion: *
 * @Author: wjj
 * @LastEditors: *
 * @LastEditTime: 2024-10-07 13:14:15
-->
<template>
  <div class="menu">
    <div class="logo">
      logo
    </div>
    <a-menu  :default-selected-keys="defaultSelectedKeys"
          :open-keys="defaultOpenKeys"
           mode="inline" 
           theme="dark" 
           @openChange="onOpenChange"
           >
      <a-sub-menu v-for="(item, index) in menuList" :key="index">
        <span slot="title"><a-icon :type="item.logo" :style="{ fontSize: '14px' }" /><span class="name">{{ item.name }}</span></span>
        <template v-for="item2 in item.submenu" >
          <a-menu-item 
             :key="item2.key"
            :class="{ active: item2.isActive }"
            @click="listChecked(item2, index)"
            v-if="!item2.role || item2.role.indexOf(role_id*1) !==-1"
            >

          <!-- <a-icon :type="item2.logo" :style="{ fontSize: '14px' }" /> -->
          <span class="name">{{ item2.title }}</span>
        </a-menu-item>
        </template>

      </a-sub-menu>
     
    </a-menu>
  </div>
</template>

<script>
export default {
  name: 'MenuList',
  props: {
    menuList: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      role_id: this.$cookie.get('role'),
      collapsed: true,
      defaultSelectedKeys: [].concat(localStorage.getItem('defaultSelectedKeys')) || ['3-1-1'],
      defaultOpenKeys: [].concat(localStorage.getItem('defaultOpenKeys')*1) || ['0'],
    }
  },
  components: {
  },
  methods: {
     onOpenChange(openKeys) {
      this.defaultOpenKeys =[openKeys[openKeys.length-1]]
    },
    listChecked(item, index) {
      
      
      localStorage.setItem('defaultOpenKeys', index);
      localStorage.setItem('defaultSelectedKeys', item.key);

      // console.log('item------', item, index)
      this.$router.push({
        path:item.path,
        params:''
      })
    }
  }
}
</script>

<style lang="less">
@import "./../assets/less/config.less";

.menu {
  // width: 80px;
  min-height: 100%;
  color: #fff;
  // background: rgb(12, 11, 60);
  // text-align: center;

  .logo {
    width: 46px;
    height: 46px;
    border-radius: 100%;
    background: #fff;
    color: @themeColor;
    text-align: center;
    line-height: 46px;
    margin: 15px auto;
  }
}
</style>