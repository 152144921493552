var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.form.getFieldValue('standards_keys')),function(key,index){return _c('a-form-item',_vm._b({key:key,attrs:{"label":index === 0 ? '拍品尺寸' : '',"required":false}},'a-form-item',index === 0 ? _vm.formItemLayout : _vm.formItemLayoutWithOutLabel,false),[_c('a-row',{attrs:{"type":"flex","gutter":[10, 0]}},[_c('a-col',{attrs:{"span":3}},[_c('a-form-item',_vm._b({attrs:{"label":"名称"}},'a-form-item',_vm.formItemLayoutStandars,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `standarsName[${index}]`,
              {
                initialValue:
                  (_vm.standarsList.standarsName &&
                    _vm.standarsList.standarsName[index]) ||
                  '',
              },
            ]),expression:"[\n              `standarsName[${index}]`,\n              {\n                initialValue:\n                  (standarsList.standarsName &&\n                    standarsList.standarsName[index]) ||\n                  '',\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"span":3}},[_c('a-form-item',_vm._b({attrs:{"label":"长"}},'a-form-item',_vm.formItemLayoutStandars,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `standarsCaliber[${index}]`,
              {
                initialValue:
                  (_vm.standarsList.standarsCaliber &&
                    _vm.standarsList.standarsCaliber[index]) ||
                  '',
              },
            ]),expression:"[\n              `standarsCaliber[${index}]`,\n              {\n                initialValue:\n                  (standarsList.standarsCaliber &&\n                    standarsList.standarsCaliber[index]) ||\n                  '',\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"span":3}},[_c('a-form-item',_vm._b({attrs:{"label":"宽"}},'a-form-item',_vm.formItemLayoutStandars,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `standarsWidth[${index}]`,
              {
                initialValue:
                  (_vm.standarsList.standarsWidth &&
                    _vm.standarsList.standarsWidth[index]) ||
                  '',
              },
            ]),expression:"[\n              `standarsWidth[${index}]`,\n              {\n                initialValue:\n                  (standarsList.standarsWidth &&\n                    standarsList.standarsWidth[index]) ||\n                  '',\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"span":3}},[_c('a-form-item',_vm._b({attrs:{"label":"高/厚"}},'a-form-item',_vm.formItemLayoutStandars,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `standarsThickness[${index}]`,
              {
                initialValue:
                  (_vm.standarsList.standarsThickness &&
                    _vm.standarsList.standarsThickness[index]) ||
                  '',
              },
            ]),expression:"[\n              `standarsThickness[${index}]`,\n              {\n                initialValue:\n                  (standarsList.standarsThickness &&\n                    standarsList.standarsThickness[index]) ||\n                  '',\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"span":3}},[_c('a-form-item',_vm._b({attrs:{"label":"直径"}},'a-form-item',_vm.formItemLayoutStandars,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `standarsDiameter[${index}]`,
              {
                initialValue:
                  (_vm.standarsList.standarsDiameter &&
                    _vm.standarsList.standarsDiameter[index]) ||
                  '',
              },
            ]),expression:"[\n              `standarsDiameter[${index}]`,\n              {\n                initialValue:\n                  (standarsList.standarsDiameter &&\n                    standarsList.standarsDiameter[index]) ||\n                  '',\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"span":3}},[_c('a-form-item',_vm._b({attrs:{"label":"重量"}},'a-form-item',_vm.formItemLayoutStandars,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `standarsWeight[${index}]`,
              {
                initialValue:
                  (_vm.standarsList.standarsWeight &&
                    _vm.standarsList.standarsWeight[index]) ||
                  '',
              },
            ]),expression:"[\n              `standarsWeight[${index}]`,\n              {\n                initialValue:\n                  (standarsList.standarsWeight &&\n                    standarsList.standarsWeight[index]) ||\n                  '',\n              },\n            ]"}],attrs:{"placeholder":"例：2.4g"}})],1)],1),_c('a-col',{attrs:{"flex":"55px"}},[(_vm.form.getFieldValue('standards_keys').length > 1)?_c('a-icon',{staticClass:"dynamic-delete-button",attrs:{"type":"minus-circle","disabled":_vm.form.getFieldValue('standards_keys').length === 1},on:{"click":() => _vm.remove(key)}}):_vm._e(),(_vm.form.getFieldValue('standards_keys').length == key + 1)?_c('a-icon',{staticClass:"dynamic-add-button",attrs:{"type":"plus-circle"},on:{"click":() => _vm.add()}}):_vm._e()],1)],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }