
<template>
  <div class="comon-list">
    <div class="comon-check-form">
      <a-form style="padding: 20px" class="searchArea" :form="form" layout="inline" :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }">
        <a-form-item label="拍品名称" style="width: 235px">
          <a-input v-decorator="['title']" placeholder="请输入" />
        </a-form-item>
        <a-form-item label="状态" style="width: 235px">
          <a-select allowClear showSearch v-decorator="['cate']" placeholder="请选择或输入搜索">
            <a-select-option v-for="item in cateList" :key="item.id" :value="item.name">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="直播间" style="width: 235px">
          <a-select allowClear v-decorator="['new_type', { initialValue: '0' }]" placeholder="请选择">
            <a-select-option v-for="item in typeList" :key="item.value" :value="item.value">{{ item.name
            }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item>
          <a-button type="primary" @click="searchGoods"> 搜索 </a-button>
        </a-form-item>
        <a-form-item>
          <a-button class="resetBtn" @click="resetSearch"> 重置 </a-button>
        </a-form-item>
      </a-form>
    </div>

    <div style="height: 500px;  margin-top: 30px">
      <a-table :columns="columns" :scroll={x:700,y:500} :data-source=" data " :pagination=" pagination "
        :loading=" loading " @change=" onChangTable ">
        <template slot="operation" slot-scope="text, record">
          <a href="javascript:;" class="operate" @click=" () => onEdit(record.pid,'edit') ">修改</a>
          <a href="javascript:;" class="operate" @click=" () => onEdit(record.pid,'check') ">查看</a>
          <a href="javascript:;" class="operate" @click=" () => onEdit(record.pid,'return') ">退货</a>
        </template>
      </a-table>
    </div>

    <a-modal 
    title="编辑商品信息" 
    :visible=" moduleVisible " 
    okText="确定" cancelText="取消" @ok="handleOk('edit')"  @cancel="moduleVisible = false">
      <a-form style="padding: 20px" class="searchArea" :form=" editform " :label-col=" { span: 7 } "
        :wrapper-col=" { span: 17 } ">
        <a-form-item label="销售价格" style="width: 235px">
          <a-input  v-decorator=" ['price'] " placeholder="请输入" />
        </a-form-item>
        <a-form-item label="直播间" style="width: 235px">
          <a-select  allowClear showSearch v-decorator=" ['onlineroom'] " placeholder="请选择或输入搜索">
            <a-select-option v-for=" item  in  cateList " :key=" item.id " :value=" item.name ">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="状态" style="width: 235px">
          <a-select  allowClear v-decorator=" ['status', { initialValue: '0' }] "
            placeholder="请选择">
            <a-select-option v-for=" item  in  typeList " :key=" item.value " :value=" item.value ">{{ item.name
              }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="备注" style="width: 235px">
          <a-input v-decorator=" ['remark'] " placeholder="请输入" type="textarea" />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal 
    title="退货操作" 
    :visible=" returnModuleVisible "
    okText="确定" cancelText="取消" @ok="handleOk('return')"  @cancel="returnModuleVisible = false">
      <a-form style="padding: 20px" class="searchArea" :form=" returnform " :label-col=" { span: 7 } "
        :wrapper-col=" { span: 17 } ">
        <a-form-item label="商品名称" style="width: 235px">
          <a-input v-decorator=" ['title'] " placeholder="请输入" />
        </a-form-item>
        <a-form-item label="商品SKU" style="width: 235px">
          <a-select allowClear showSearch v-decorator=" ['cate'] " placeholder="请选择或输入搜索">
            <a-select-option v-for=" item  in  cateList " :key=" item.id " :value=" item.name ">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="选择退货单" style="width: 235px">
          <a-select allowClear v-decorator=" ['new_type', { initialValue: '0' }] " placeholder="请选择">
            <a-select-option v-for=" item  in  typeList " :key=" item.value " :value=" item.value ">{{ item.name
              }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>


    </a-modal>

  </div>
</template>
  
  
  
<script>
import {
  h
} from "vue";

import { goodsApi } from "@/api/good";
import {orderApi} from "@/api/order"
import {
  message
} from "ant-design-vue";

export default {
  name: "GoodsList",
  data() {
    return {
      data: [],
      columns: [{
        title: "内部编码",
        dataIndex: "code",
        width: '150px',
        fixed: 'left',

        scopedSlots: {
          customRender: "code",
        },
      },
      {
        title: "商品名称",
        dataIndex: "title",
        width: '150px',
        fixed: 'left',
        scopedSlots: {
          customRender: "title",
        },
      },
      {
        title: "平台编码",
        dataIndex: "sku",
        width: '150px',


        scopedSlots: {
          customRender: "sku",
        },
      },

      {
        title: "采购价",
        dataIndex: "in_price",
        width: '90px',
        scopedSlots: {
          customRender: "in_price",
        },
        customRender: (value, record) => {
          return parseInt(value)
        }
      },
      {
        title: "销售价",
        dataIndex: "mid_price",
        width: '90px',
        scopedSlots: {
          customRender: "mid_price",
        },
        customRender: (value, record) => {
          return parseInt(value)
        }
      },
      {
        title: "采购时间",
        dataIndex: "cate_name",
        width: '120px',
        scopedSlots: {
          customRender: "cate_name",
        },
      },
      {
        title: "备注信息",
        dataIndex: "remark",
        width: '90px',
        scopedSlots: {
          customRender: "remark",
        },
      },
      {
        title: "供货方",
        dataIndex: "to_username_name",
        width: '90px',
        scopedSlots: {
          customRender: "to_username_name",
        },
      },
      {
        title: "状态",
        dataIndex: "refund_status",
        width: '200px',
        scopedSlots: {
          customRender: "refund_status",
        },
        customRender: (value, record) => {
          if (value == 1) {
              return '已申请'
            }
            if (value == 2) {
              return '已驳回'
            }
            if (value == 3) {
              return '已完成'
            }
            return '--'

        }
      },

      {
        title: "操作",
        dataIndex: "operation",
        width: '150px',
        fixed: 'right',
        scopedSlots: {
          customRender: "operation",
        },
      },
      ],
      selectedRowKeys: [],
      loading: false,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 50,
      },
      typeList: [
        {
          name: '全部',
          value: '0'
        },
        {
          name: '生意货',
          value: '1'
        },
        {
          name: '臻品',
          value: '2'
        },
      ],
      cateList: [],
      fromUsernameList: [],
      toUsernameList: [],
      goodStatus: 1,
      moduleVisible:false,
      returnModuleVisible:false,
      modalType:'check'
    };
  },

  beforeCreate() {
    if (this.$cookie.get('username')) {
      this.form = this.$form.createForm(this, {
        name: "validate_other",
      });
    } else {
      message.warning('请登录')
      this.$router.push({ path: '/login' })
    }
  },
  mounted() {
    this.getCateList();
    this.searchGoods();
    message.config({
      top: `100px`,
      duration: 8,
    });
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  methods: {


    //重置搜索条件
    resetSearch() {
      this.form.resetFields();
      this.pagination.current = 1
      this.searchGoods();
    },
    //分类-行制-委托人列表
    getCateList() {
     goodsApi.getcatelist()
        .then((res) => {
          this.cateList = res.data.data;
        });
     goodsApi.gettofromlist().then(res => {
        this.fromUsernameList = res.data.data.clientsList
        this.toUsernameList = res.data.data.receiverList
      })
    },
    //获取表格数据
    getTable(data) {
      this.loading = true;
     goodsApi.getproductlist(data)
        .then((res) => {
          console.log(res, "resres");
          if (res.data.status == 200 && res.data.data.list.length > 0) {
            this.data = []
            this.selectedRowKeys = []
            this.pagination.total = +res.data.data.total;

            res.data.data.list.map(good => {
              this.data.push({ ...good, key: good.pid })
              //  if(good.on_status == 1){
              //   this.selectedRowKeys.push(good.pid)
              //  }

            })
          } else {
            this.data = [];
            this.pagination.total = 0;
          }
        })
        .finally((res) => {
          this.loading = false;
        });
    },
    //翻页
    onChangTable(page) {
      this.pagination.current = page.current;
      this.searchGoods()
    },
    //搜索
    searchGoods() {
      this.form.validateFields((err, values) => {
        let params = {
          page: this.pagination.current,
          pageSize: 50,
          ...values,
          cate: values.cate ? this.cateList.filter(item => item.name == values.cate)[0].id : '',
          to_username: values.to_username ? this.toUsernameList.filter(item => item.name == values.to_username)[0].id : '',
          from_username: values.from_username ? this.fromUsernameList.filter(item => item.name == values.from_username)[0].id : '',
          onstatus: this.goodStatus,
          creator_id: this.$cookie.get('userid'),
          shop_id:this.$cookie.get('userid')

        };
        this.getTable(params);
      });
    },
    //编辑
    onEdit(pid,type) {
      switch(type){
        case 'edit':
          this.moduleVisible = true
          this.modalType = 'edit'

          break;
          case 'check':
          this.$router.push({ path: '/goods/publish' })
          break;
          case 'return':
          this.returnModuleVisible = true
          break;
          default:
          break;
      }
    },
    
  },
};
</script>
  
<style lang="less" scoped></style>