
<template>
  <div class="comon-list goods-list">
    <div class="comon-publish ">
      <a-button type="primary" @click="$router.push({ path: '/goods/publish' })">
        发布拍品
      </a-button>
    </div>
    <div class="comon-check-form">
      <a-form style="padding: 20px" class="searchArea" :form="form" layout="inline" :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }">
        <a-form-item label="拍品名称" style="width: 235px">
          <a-input v-decorator="['title']" placeholder="请输入" />
        </a-form-item>
        <a-form-item label="内部编码" style="width: 235px">
          <a-input v-decorator="['sku']" placeholder="请输入" />
        </a-form-item>
        <a-form-item label="拍品分类" style="width: 235px">
          <a-cascader v-decorator="[
            'cateArr',
            {
              initialValue: '',

            },
          ]" placeholder="请选择或输入搜索" allowClear 
            :field-names="{ label: 'name', value: 'code', children: 'items' }" :options="cateList" />
         
        </a-form-item>
        <a-form-item label="委托方" style="width: 235px">
          <a-select @change="changefromUsername" allowClear showSearch v-decorator="['from_username']"
            placeholder="请选择或输入搜索">
            <a-select-option v-for="item in fromUsernameList" :key="item.id" :value="item.name">{{ item.name
            }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item>
          <a-button type="primary" @click="searchGoods"> 搜索 </a-button>
        </a-form-item>
        <a-form-item>
          <a-button class="resetBtn" @click="resetSearch"> 重置 </a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-radio-group @change="changStatus" button-style="solid" v-model="goodStatus" :style="{ marginTop: '20px' }">
      <a-radio-button :value="1">
        上架
      </a-radio-button>
      <a-radio-button :value="2">
        下架
      </a-radio-button>
    </a-radio-group>
    <div style="margin: 16px 0">
      <span>
        <template v-if="hasSelected">
          {{ `已经选中 ${selectedRowKeys.length} 件` }}
        </template>
      </span>
      <a-button style="margin-left: 8px" @click="() => batchChange(2)">
        批量{{ goodStatus == 1 ? '下架' : '上架' }}
      </a-button>
      <a-button style="margin-left: 8px" v-if="goodStatus == 1" @click="goRetail">
        批量发布到渠道
      </a-button>
      <a-button style="margin-left: 8px" @click="() => batchChange(1)">
        批量删除
      </a-button>
      <a-button style="margin-left: 8px" @click="exportAll">
        批量导出
      </a-button>
      <a-button style="margin-left: 8px" @click="printOpen">
        批量打印
      </a-button>

    </div>
    <div style="height: 500px;  margin-top: 30px">
      <a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns"
        :scroll={x:700,y:500} :data-source="data" :pagination="pagination" :loading="loading" @change="onChangTable">
        <!-- :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }" -->
        <template slot="operation" slot-scope="text, record">
          <div><a href="javascript:;" class="operate" @click="() => onEdit(record.pid)">编辑</a></div>

          <div><a-popconfirm v-if="data.length" :title="record.on_status == 1 ? '确定下架此拍品吗？' : '确定上架此拍品吗'
            " ok-text="确定" cancel-text="取消" @confirm="() => onEditStatus(record.pid)">
              <a href="javascript:;" :class="record.on_status == 1 ? 'operate' : 'operate'">{{
              record.on_status == 1 ? "下架" : "上架"
            }}</a>
            </a-popconfirm></div>
          <div><a-popconfirm v-if="data.length" title="确定删除此拍品吗？" ok-text="确定" cancel-text="取消"
              @confirm="() => onDelete(record.pid)">
              <a href="javascript:;" class="operate grey">删除</a>
            </a-popconfirm></div>
          <div><a href="javascript:;" class="operate grey" @click="() => operDiar(record.pid)">操作日志</a></div>
        </template>
      </a-table>
    </div>

    <a-modal class="preview" :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>

    <a-modal title="批量打印" :visible="moduleVisible" okText="打印" cancelText="取消" @ok="printAll"
      @cancel="moduleVisible = false">
      <a-form style="padding: 20px" class="searchArea" :form="editform" :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }">
        <a-form-item label="打印机" style="width: 400px">
          <a-select allowClear v-decorator="['code']" placeholder="请选择">
            <a-select-option v-for=" item  in  printList " :key="item.code" :value="item.code">{{ item.name + '-' +
              item.code
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>



<script>
import {
  h
} from "vue";

import { goodsApi } from "@/api/good"
import {
  message, Modal
} from "ant-design-vue";

export default {
  name: "GoodsList",
  data() {
    return {
      moduleVisible: false,
      data: [],
      columns: [
        {
          title: "内部编码",
          dataIndex: "sku",
          width: '150px',
          // fixed: 'left',

          scopedSlots: {
            customRender: "sku",
          },
          customRender: (value, record) => {
            if (record.cid != 0) {
              return <div style="color:#1890ff">{value}</div>
            } else {
              return value
            }
          }
        },
        {
          title: "拍品名称",
          dataIndex: "title",
          width: '150px',
          scopedSlots: {
            customRender: "title",
          },
        },
        {
          title: "拍品照片",
          dataIndex: "img",
          width: '140px',
          scopedSlots: {
            customRender: "img",
          },
          customRender: (value, record) => {


            if (value && value.includes('[')) {
              let obj = JSON.parse(value);
              const array = [];

              obj.map((img, index) => {
                if (img && index === 0) {
                  array.push(
                    h(
                      "img", {
                      style: {
                        width: "70px",
                        height: "70px",
                        marginLeft: "5px",
                      },
                      attrs: {
                        src: img.url,
                      },
                      on: {
                        click: () => this.picPreview(img.url)
                      }
                    },
                      () => item
                    )
                  );

                }

              });
              return h("div", array);

            } else {
              return '--'
            }
          },
        },
        {
          title: "售价",
          dataIndex: "sale_price",
          width: '90px',
          scopedSlots: {
            customRender: "sale_price",
          },
          customRender: (value, record) => {
            return parseInt(value)
          }
        },
        {
          title: "零售价",
          dataIndex: "retail_price",
          width: '110px',
          scopedSlots: {
            customRender: "retail_price",
          },
        },
        {
          title: "拍品分类",
          dataIndex: "cate_name",
          width: '120px',
          scopedSlots: {
            customRender: "cate_name",
          },
        },
        {
          title: "尺寸",
          dataIndex: "standards",
          width: '140px',
          scopedSlots: {
            customRender: "standards",

          },
          customRender: (value, record) => {
            let obj = JSON.parse(value);
            let lenArr = []
            Object.keys(obj).map(key => {
              lenArr.push(obj[key].length)
            })
            let maxLen = lenArr.sort((a, b) => b - a)[0] || 0
            if (maxLen > 0) {
              const array = [];
              for (let i = 0; i < maxLen; i++) {
                let str = []
                str.push(
                  h("div",
                    {
                      style: {
                        textAlign: 'left'
                      }
                    },
                    '规格:' + `${obj.standarsCaliber[i] + '*' || ''}${obj.standarsWidth[i] || ''}${'*' + obj.standarsThickness[i] || ''}`)
                )
                if (obj.standarsDiameter[i]) {
                  str.push(
                    h("div",
                      {
                        style: {
                          textAlign: 'left'
                        }
                      },
                      '直径:' + `${obj.standarsDiameter[i]}`)
                  )

                }

                if (obj.standarsDiameter[i]) {
                  str.push(
                    h("div",
                      {
                        style: {
                          textAlign: 'left'
                        }
                      },
                      '重量:' + `${obj.standarsWeight[i] || ''}`)
                  )

                }
                array.push(
                  h("div",
                    {
                      style: {
                        textAlign: 'left'
                      }
                    },
                    str)
                )
              }
              return h('div', array)

            } else {
              return '--'
            }

          },
        },
        {
          title: "货架",
          dataIndex: "shelves",
          width: '120px',
          scopedSlots: {
            customRender: "shelves",
          },
        },
        {
          title: "委托方",
          dataIndex: "from_username_name",
          width: '90px',
          scopedSlots: {
            customRender: "from_username_name",
          },
        },
        {
          title: "提交时间",
          dataIndex: "create_time",
          width: '200px',
          scopedSlots: {
            customRender: "create_time",
          },
        },

        {
          title: "操作",
          dataIndex: "operation",
          width: '120px',
          // fixed: 'right',
          scopedSlots: {
            customRender: "operation",
          },
        },
      ],
      selectedRowKeys: [],
      loading: false,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 50,
      },
      typeList: [
        {
          name: '全部',
          value: '0'
        },
        {
          name: '生意货',
          value: '1'
        },
        {
          name: '臻品',
          value: '2'
        },
      ],
      cateList: [],
      fromUsernameList: [],
      toUsernameList: [],
      printList: [],
      goodStatus: 1,
      previewVisible: false,
      previewImage: "",
    };
  },


  beforeCreate() {
    if (this.$cookie.get('username')) {
      this.form = this.$form.createForm(this, {
        name: "validate_other",
      });
      this.editform = this.$form.createForm(this, {
        name: "validate_edit",
      });
    } else {
      message.warning('请登录')
      this.$router.push({ path: '/login' })
    }
  },
  mounted() {
    this.getCateList();
    this.searchGoods();
    message.config({
      top: `100px`,
      duration: 8,
    });
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  methods: {
    printOpen() {
      this.moduleVisible = true

    },
    printAll() {
      if (+this.selectedRowKeys.length > 50) {
        Modal.info({
          content: '打印数据不可超出50条',
          cancelText: '好的，知道了',
          onOk() {
            Modal.destroyAll();
          },
        });

      } else {
        this.editform.validateFields((err, values) => {
          let info = []
          this.data.map(item=>{
            if(this.selectedRowKeys.indexOf(item.pid) > -1){
              info.push(item.code)
            }
          })
          let params = {
            info: info.join(','),
            code: values.code,
          }
          goodsApi.printapi(params).then(res => {
            if (res.data.status == 200) {
              message.success('批量打印成功')
              this.selectedRowKeys = []
              this.moduleVisible = false
            }
          })

        })


      }



    },

    exportAll() {
      if (+this.pagination.total > 500) {
        Modal.info({
          content: '导出数据不可超出500条',
          cancelText: '好的，知道了',
          onOk() {
            Modal.destroyAll();
          },
        });

      } else {
        this.form.validateFields((err, values) => {
          let params = {
            page: this.pagination.current,
            pageSize: 50,
            ...values,
            cate: values.cateArr ? values.cateArr[values.cateArr.length - 1] : '',
            to_username: values.to_username ? this.toUsernameList.filter(item => item.name == values.to_username)[0].id : '',
            from_username: values.from_username ? this.fromUsernameList.filter(item => item.name == values.from_username)[0].id : '',
            onstatus: this.goodStatus,
          };
          goodsApi.exportproductexcel(params).then(res => {
            if (res.status == 200) {
              message.success('批量导出拍品表格成功')
              const blob = new Blob([res.data], { type: 'text/xml' });
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = '拍品表格.xlsx';
              link.click();
            }
          })

          goodsApi.downloadimg(params).then(res => {
            if (res.status == 200) {
              message.success('批量导出拍品照片成功')
              const blob = new Blob([res.data], { type: 'application/zip,charset=utf-8' });
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = '拍品照片.zip';
              link.click();
            }
          })

        });
      }
    },

    //批量发布到渠道
    goRetail() {
      let data = {
        pids: this.selectedRowKeys.join(','),
      }
      goodsApi.sendproductchannel(data).then(res => {
        if (res.data.status == 200) {
          message.success('批量发布成功')
          this.searchGoods()
          this.selectedRowKeys = []
        }
      })


    },
    //批量删除/批量上架、下架
    //批量上架、下架
    batchChange(type) {
      let data = {
        pids: this.selectedRowKeys.join(','),
        type: type == 1 ? 1 : this.goodStatus == 1 ? 2 : 3,
      }
      let str = type == 1 ? '删除' : this.goodStatus == 1 ? '下架' : '上架'
      goodsApi.oprateproductmulti(data).then(res => {
        if (res.data.status == 200) {
          message.success(`批量${str}成功`)
          this.searchGoods()
          this.selectedRowKeys = []
        }
      })
    },

    //重置搜索条件
    resetSearch() {
      this.form.resetFields();
      this.pagination.current = 1
      this.searchGoods();
    },
    //图片预览
    picPreview(src) {
      if (src) {
        this.previewImage = src
        this.previewVisible = true;
      }
    },
    //关闭预览
    handleCancel() {
      this.previewImage = ''
      this.previewVisible = false;
    },
    //分类-行制-委托人列表
    getCateList() {
      goodsApi.listprint()
        .then((res) => {
          this.printList = res.data.data.list;
        });
      goodsApi.getcatelistnew()
        .then((res) => {
          this.cateList = res.data.data;
        });
      goodsApi.gettofromlist().then(res => {
        this.fromUsernameList = res.data.data.clientsList
        this.toUsernameList = res.data.data.receiverList
      })
    },
    //获取表格数据
    getTable(data) {
      this.loading = true;

      goodsApi.getproductlist(data)
        .then((res) => {
          if (res.data.status == 200 && res.data.data.list.length > 0) {
            this.data = []
            this.selectedRowKeys = []
            this.pagination.total = +res.data.data.total;

            res.data.data.list.map(good => {
              this.data.push({ ...good, key: good.pid })

            })
          } else {
            this.data = [];
            this.pagination.total = 0;
          }
        })
        .finally((res) => {
          this.loading = false;
        });
    },
    //翻页
    onChangTable(page) {
      this.pagination.current = page.current;
      this.searchGoods()
    },
    changStatus() {
      this.resetSearch()
    },
    changefromUsername(value) {
      let pid = this.fromUsernameList.filter(item => item.name == value)[0].pid
      let hasTo = this.toUsernameList.filter(item => item.id == pid)[0]
      let name = ''
      if (hasTo) {
        name = hasTo.name
      }
      this.form.setFieldsValue({ to_username: name })
    },
    //搜索
    searchGoods() {
      this.form.validateFields((err, values) => {
        let params = {
          page: this.pagination.current,
          pageSize: 50,
          ...values,
          cate: values.cateArr ? values.cateArr[values.cateArr.length - 1] : '',
          from_username: values.from_username ? this.fromUsernameList.filter(item => item.name == values.from_username)[0].id : '',
          onstatus: this.goodStatus,
        };
        this.getTable(params);
      });
    },
    //删除
    onDelete(pid) {
      let data = {
        pid
      }
      goodsApi.delproduct(data)
        .then((res) => {
          if (res.data.status == 200) {
            message.success(res.data.message);
            this.pagination.current = 1;
            this.searchGoods();
          }
        });
    },
    //操作日记页
    operDiar(pid) {
      this.$router.push({
        path: "/goods/operDiar",
        query: {
          pid: pid,
        },
      });
    },
    //编辑
    onEdit(pid) {
      this.$router.push({
        path: "/goods/publish",
        query: {
          pid: pid,
        },
      });
    },
    //上架、下架
    onEditStatus(pid) {
      let params = {
        pid,
      }
      goodsApi.getproduct(params).then(res => {
        if (res.data.status == 200) {
          let editData = res.data.data
          let params = {
            ...editData,
            on_status: editData.on_status == 1 ? 2 : 1,
          }
          goodsApi.editproduct(params).then(res => {
            if (res.data.status == 200) {
              message.success(`${editData.on_status == 1 ? '下架' : '上架'}成功`)
              this.searchGoods();
            }
          })
        }
      });
    },

    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.find((item) => key === item.key);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
  },
};
</script>

<style lang="less" scoped>
@import "./../../../assets/less/reset.less";
@import "./../../../assets/less/config.less";
</style>