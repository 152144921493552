<template>
    <div class=" comon-list martDetail">
        <h2 style="margin-bottom: 20px;">直播计划的商品池</h2>

        <div class="comon-check-form ">
            <a-form style="padding: 20px" class="searchArea" :form="form" layout="inline" :label-col="{ span: 7 }"
                :wrapper-col="{ span: 17 }">
                <a-form-item label="商品名称" style="width: 235px">
                    <a-input v-decorator="['title']" placeholder="请输入" />
                </a-form-item>
                <a-form-item label="商品分类" style="width: 235px">
                    <a-cascader v-decorator="[
                        'cateArr',
                        {
                            initialValue: '',

                        },
                    ]" placeholder="请选择或输入搜索" allowClear showSearch
                        :field-names="{ label: 'name', value: 'code', children: 'items' }" :options="cateList" />
                </a-form-item>
                <a-form-item label="商品编码" style="width: 235px">
                    <a-input v-decorator="['sku']" placeholder="请输入" />
                </a-form-item>
                <a-form-item label="排品逻辑" style="width: 235px">
                    <a-select v-decorator="[
                        'new_type',
                        {
                            initialValue: '',

                        },
                    ]" placeholder="请选择" allowClear>
                        <a-select-option v-for="item in typeList" :key="item.value" :value="item.value">{{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" @click="searchMart"> 搜索 </a-button>
                </a-form-item>
                <a-form-item>
                    <a-button class="resetBtn" @click="resetSearch"> 重置 </a-button>
                </a-form-item>
            </a-form>
        </div>
        <!-- <a-tabs type="card" @change="callback">
            <a-tab-pane key="1" tab="拍品"> -->
        <div style="maxHeight: 750px; margin-top: 30px">
            <a-table id="mytable" :pagination="false" :columns="goodsColumns" :scroll="{x:700,y:500}"
                :data-source="goodsData" :loading="loading" @change="onChangTable">
                <template slot="operation" slot-scope="text, record">
                    <a v-if="record.status == 1" href="javascript:;" class="operate grey"
                        @click="() => onEdit(record, 3)">售出</a>
                    <a v-if="record.status == 3" href="javascript:;" class="operate disa">已售出</a>
                    <a v-if="record.status == 1" href="javascript:;" class="operate grey"
                        @click="() => onEdit(record, 4)">归还</a>
                    <a v-if="record.status == 4" href="javascript:;" class="operate disa">已归还</a>
                    <a v-if="record.in_dou == 0" href="javascript:;" @click="() => inDouYin(record)" class="operate ">同步</a>

                    <a-popconfirm v-if="goodsData.length && martStatus != 3 && record.status == 1" title="确定删除此拍品吗？"
                        ok-text="确定" cancel-text="取消" @confirm="() => onEdit(record, 2)">
                        <a href="javascript:;" class="operate">删除</a>
                    </a-popconfirm>
                </template>
            </a-table>
            <div class="footer">
                <a-button @click="handleBack" class=" footerBtn"> 返回</a-button>
                <a-button v-if="martStatus == 4" type="primary" @click="handleAdd" class=" footerBtn"> 继续添加</a-button>
                <!-- <a-button v-if="martStatus == 4" type="primary" @click="handleSubmit" class="footerBtn submitBtn">
                            确认提交 </a-button> -->
            </div>
        </div>
        <!-- </a-tab-pane> -->
        <!-- <a-tab-pane key="2" tab="下载记录">
                <div style="height: 500px; margin-top: 30px">
                    <a-table :columns="downloadColumns" :scroll="{ x: 700, y: 500 }" :data-source="downloadData">
                        <template slot="operation" slot-scope="text, record">
                            <a-popconfirm v-if="downloadData.length" title="确定下载此数据吗？" ok-text="确定" cancel-text="取消"
                                @confirm="() => download(record.pid)">
                                <a href="javascript:;" class="operate">下载</a>
                            </a-popconfirm>
                        </template>
                    </a-table>
                </div>
            </a-tab-pane> -->
        <!-- </a-tabs> -->

        <a-modal class="preview" :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
    </div>
</template>



<script>
import {
    h
} from "vue";
import { reactive } from '@vue/reactivity';

import { orderApi } from "@/api/order";
import { goodsApi } from "@/api/good";
import {
    message
} from "ant-design-vue";
export default {
    name: "martDetail",
    data() {
        return {
            cateList: [],

            typeList: [

                {
                    name: '福利款',
                    value: '1'
                },
                {
                    name: '承接款',
                    value: '2'
                },
                {
                    name: '利润款',
                    value: '3'
                },
                {
                    name: '精品款',
                    value: '4'
                },
                {
                    name: '挂链款',
                    value: '5'
                },
            ],
            goodsData: [],
            goodsColumns: [
                {
                    title: "序号",
                    dataIndex: "index",
                    width: '70px',
                    customRender: (text, record, index) => `${index + 1}`,

                },
                {
                    title: "商品编码",
                    dataIndex: "sku",
                    width: '90px',
                    scopedSlots: {
                        customRender: "sku",
                    },
                },
                {
                    title: "图片",
                    dataIndex: "img",
                    width: '90px',
                    scopedSlots: {
                        customRender: "img",
                    },
                    customRender: (value, record) => {
                        if (value && value.includes('[')) {
                            let obj = JSON.parse(value);
                            const array = [];

                            obj.map((img, index) => {
                                if (img && index === 0) {
                                    array.push(
                                        h(
                                            "img", {
                                            style: {
                                                width: "70px",
                                                height: "70px",
                                                marginLeft: "5px",
                                            },
                                            attrs: {
                                                src: img.url,
                                            },
                                            on: {
                                                click: () => this.picPreview(img.url)
                                            }
                                        },
                                            () => item
                                        )
                                    );

                                }

                            });
                            return h("div", array);

                        } else {
                            return '--'
                        }
                    },
                },
                {
                    title: "拍品名称",
                    dataIndex: "title",
                    width: '90px',
                    scopedSlots: {
                        customRender: "title",
                    },
                },
                {
                    title: "类别",
                    dataIndex: "cate_name",
                    // width: '150px',
                    scopedSlots: {
                        customRender: "cate_name",
                    },
                },
                {
                    title: "尺寸规格",
                    dataIndex: "standards",
                    width: '140px',
                    scopedSlots: {
                        customRender: "standards",
                    },
                    customRender: (value, record) => {
                        let obj = JSON.parse(value);
                        let lenArr = []
                        Object.keys(obj).map(key => {
                            lenArr.push(obj[key].length)
                        })
                        let maxLen = lenArr.sort((a, b) => b - a)[0] || 0
                        if (maxLen > 0) {
                            const array = [];
                            for (let i = 0; i < maxLen; i++) {
                                let str = []
                                str.push(
                                    h("div",
                                        {
                                            style: {
                                                textAlign: 'left'
                                            }
                                        },
                                        '规格:' + `${obj.standarsCaliber[i] + '*' || ''}${obj.standarsWidth[i] || ''}${'*' + obj.standarsThickness[i] || ''}`)
                                )
                                if (obj.standarsDiameter[i]) {
                                    str.push(
                                        h("div",
                                            {
                                                style: {
                                                    textAlign: 'left'
                                                }
                                            },
                                            '直径:' + `${obj.standarsDiameter[i]}`)
                                    )

                                }

                                if (obj.standarsDiameter[i]) {
                                    str.push(
                                        h("div",
                                            {
                                                style: {
                                                    textAlign: 'left'
                                                }
                                            },
                                            '重量:' + `${obj.standarsWeight[i] || ''}`)
                                    )

                                }

                                array.push(
                                    h("div",
                                        {
                                            style: {
                                                textAlign: 'left'
                                            }
                                        },
                                        str)
                                )
                            }
                            return h('div', array)

                        } else {
                            return '--'
                        }

                    },
                },
                {
                    title: "排品逻辑",
                    dataIndex: "new_type",
                    width: '80px',
                    scopedSlots: {
                        customRender: "new_type",
                    },
                    customRender: (value, record) => {
                        let text = this.typeList.filter(item => item.value == value)[0].name
                        return text
                    }
                },
                {
                    title: "售价",
                    dataIndex: "sale_price",
                    width: '100px',
                    scopedSlots: {
                        customRender: "sale_price",
                    },
                },
                {
                    title: "原价",
                    dataIndex: "guide_price",
                    width: '90px',
                    scopedSlots: {
                        customRender: "guide_price",
                    },
                },
                {
                    title: "抖店同步",
                    dataIndex: "in_dou",
                    width: '90px',
                    scopedSlots: {
                        customRender: "in_dou",
                    },
                    customRender: (value, record) => {
                        let text = value == 0 ? '不在抖店' : '在抖店'
                        return text
                    }
                },
                {
                    title: "货架",
                    dataIndex: "shelves",
                    width: '70px',
                    scopedSlots: {
                        customRender: "shelves",
                    },
                },
                {
                    title: "操作",
                    dataIndex: "operation",
                    width: '170px',
                    fixed: 'right',
                    scopedSlots: {
                        customRender: "operation",
                    },
                },
            ],
            isLoading: false,
            loading: false,
            page: 1,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 400
            },
            downloadColumns: [{
                title: "文件名",
                dataIndex: "s_time",
                // width: '150px',
                scopedSlots: {
                    customRender: "s_time",
                },
            },
            {
                title: "类型",
                dataIndex: "place",
                // width: '150px',
                scopedSlots: {
                    customRender: "place",
                },
            },
            {
                title: "状态",
                dataIndex: "nums",
                // width: '90px',
                scopedSlots: {
                    customRender: "img",
                },

            },
            {
                title: "总数（条）",
                dataIndex: "title",
                // width: '90px',
                scopedSlots: {
                    customRender: "title",
                },
            },
            {
                title: "成功（条）",
                dataIndex: "cate",
                // width: '150px',
                scopedSlots: {
                    customRender: "cate",
                },
            },
            {
                title: '失败（条）',
                dataIndex: "mid_price",
                // width: '150px',
                scopedSlots: {
                    customRender: "mid_price",
                },
            },
            {
                title: '申请时间',
                dataIndex: "mid_price",
                // width: '150px',
                scopedSlots: {
                    customRender: "mid_price",
                },
            },
            {
                title: "操作",
                dataIndex: "operation",
                width: '70px',
                fixed: 'right',
                scopedSlots: {
                    customRender: "operation",
                },
            },

            ],
            downloadData: [],
            martStatus: this.$route.query.status,
            martId: this.$route.query.id,
            sourceObj: '',// 源目标数据序号
            sourceIndex: '',
            targetIndex: '',
            targetObj: '',// 目标数据序号
            previewVisible: false,
        };
    },



    beforeCreate() {
        if (this.$cookie.get('username')) {
            this.form = this.$form.createForm(this, {
                name: "validate_other",
            });
        } else {
            message.warning('请登录')
            this.$router.push({ path: '/login' })
        }
    },
    mounted() {
        this.getCateList();

        this.searchMart()
        // this.scroll()
    },
    methods: {
        //图片预览
        picPreview(src) {
            if (src) {
                this.previewImage = src
                this.previewVisible = true;
            }
        },
        //关闭预览
        handleCancel() {
            this.previewImage = ''
            this.previewVisible = false;
        },
        //重置搜索条件
        resetSearch() {
            this.form.resetFields();
            this.searchMart();
        },
        getCateList() {
            goodsApi.getcatelistnew()
                .then((res) => {
                    this.cateList = res.data.data;
                });



        },
        //滚动分页加载数据
        //  scroll() {
        //      this.isLoading = true;//是否有数据可以加载
        //     let dom = document.querySelector(".ant-table-body")
        //     dom.onscroll = async () => {
        //         // 距离底部200px时加载一次

        //         let bottomOfWindow = dom.scrollHeight - dom.scrollTop  <= 600;
        //         console.log(dom.scrollHeight,dom.scrollTop,bottomOfWindow,'dom')
        //         if (bottomOfWindow && this.isLoading == true) {
        //             this.page = this.page + 1;//每次分页+1
        //             const data = {
        //                 fid: this.$route.query.id,
        //                 pageSize: this.pagination.pageSize,
        //                 page: this.page,
        //             }
        //             this.getTable( data);//自己封装的请求数据的方法
        //         }
        //     }
        // },

        customRow(record, index) {
            // console.log(record, index);// 这里输出是表格全部的数据
            return {
                props: {
                    // draggable: true
                },
                domProps: {
                    draggable: 'true'
                },
                style: {
                    cursor: 'pointer'
                },
                on: {
                    // 鼠标移入
                    mouseenter: (event) => {
                        // 兼容IE
                        var ev = event || window.event;
                        console.log(ev, 'eeeee')
                        ev.target.draggable = true
                        if (ev.clientX < 390) {
                            ev.target.draggable = true; // 让你要拖动的行可以拖动，默认不可以
                            console.log(record, index, 'onMouseenter');

                        } else {
                            ev.target.draggable = false
                        }

                    },
                    // 开始拖拽
                    dragstart: (event) => {
                        // 兼容IE
                        var ev = event || window.event;
                        // 阻止冒泡
                        ev.stopPropagation();
                        // 得到源目标数据序号
                        this.sourceObj = record;
                        this.sourceIndex = index
                        console.log(record, index, 'source');
                    },
                    // 拖动元素经过的元素
                    dragover: (event) => {
                        // 兼容 IE
                        var ev = event || window.event;
                        // 阻止默认行为
                        ev.preventDefault();
                        ev.dataTransfer.dropEffect = 'move'   // 可以去掉拖动时那个＋号
                        this.targetIndex = index
                    },
                    // 鼠标松开
                    drop: (event) => {
                        // 兼容IE
                        var ev = event || window.event;
                        // 阻止冒泡
                        ev.stopPropagation();
                        // 得到目标数据序号
                        this.targetObj = record;
                        this.targetIndex = index

                        console.log(this.goodsData, 'gggggg00000')
                        if (this.targetIndex === this.sourceIndex) return
                        // 这里就是让数据位置互换，让视图更新 你们可以看record，index的输出，看是什么
                        let tempDta = this.goodsData;
                        console.log(tempDta, this.goodsData, this.targetObj.sorts, this.sourceObj.sorts, 'gggggg1111')
                        // debugger
                        // tempDta[this.targetObj.sorts] = this.sourceObj;
                        // tempDta[this.sourceObj.sorts] = this.targetObj;

                        tempDta.splice(this.sourceIndex, 1)
                        tempDta.splice(this.targetIndex, 0, this.sourceObj)
                        console.log(tempDta, this.goodsData, 'gggggg2222')

                        this.goodsData = JSON.parse(JSON.stringify(tempDta))

                        let weightList = [];
                        tempDta.forEach((item, index) => {
                            item.sorts = index;
                            weightList.push({
                                id: item.id,
                                sorts: index,
                            });
                        });
                        console.log(weightList, 'weightList')
                        this.handleWeightModify({ data: JSON.stringify(weightList) });// 更改顺序接口


                    }

                }


            };
        },

        handleWeightModify(data) {
            orderApi.resortfilming(data)
                .then((res) => {
                    console.log(res, "resres");
                    if (res.data.status == 200) {
                        // this.searchMart()

                    } else {

                    }
                })
                .finally((res) => {
                    this.loading = false;
                });



        },

        //搜索
        searchMart() {
            console.log(this.$route.query, 'this.$route.query')
            this.form.validateFields((err, values) => {
                let params = {
                    ...values,
                    cate_id: values.cateArr[1],
                    fid: this.$route.query.id,
                    page: this.pagination.current,
                    pageSize: this.pagination.pageSize,
                    creator_id: this.$cookie.get('userid'),
                    shop_id: this.$cookie.get('userid')
                };
                this.getTable(params);
            });
        },
        //获取表格数据
        getTable(data) {
            this.loading = true;
            orderApi.filmingdetailslist(data)
                .then((res) => {
                    console.log(res, "resres");
                    // res.data.status == 200 && res.data.data.list.length > 0
                    if (res.data.status == 200) {
                        this.isLoading = true
                        this.goodsData = []
                        this.pagination.total = +res.data.data.total;
                        // this.goodsData.push(...res.data.data.list.map((good, index) => { return { ...good, sorts: index } }))
                        this.goodsData = res.data.data.list.map((good, index) => { return { ...good, sorts: index } })
                    } else {
                        message.warning('暂无更多数据信息！')
                        this.isLoading = false
                    }
                })
                .finally((res) => {
                    this.loading = false;
                });
        },
        //翻页
        onChangTable(page) {
            this.pagination.current = page.current;
            this.pagination.pageSize = page.pageSize;
            this.searchMart()
        },
        handleBack() {
            this.$router.push({ path: '/retail/martList' })

        },
        handleAdd() {
            this.$router.push({ path: '/retail/retailGoods' })

        },
        handleSubmit() {
            let data = {
                id: this.martId,
                status: 1

            }
            orderApi.setfilmingstatus(data).then(res => {
                if (res.data.status == 200) {
                    message.success('提交拍场成功')
                    this.martStatus = 1
                } else {
                    message.error(res.data.message)
                }

            })


        },
        //同步到抖音
        inDouYin(item) {
            let params = {
                in_dou: 0,
                id: item.id

            }
            orderApi.setdoustatus(params).then(res => {
                if (res.data.status == 200) {
                    message.success('成功同步到抖音')
                } else {
                    message.error(res.data.message)
                }

            }).finally(() => {
                this.searchMart()
            })

        },
        //删除/售出/归还某个拍品
        onEdit(item, status) {
            let data = {
                status,
                id: item.id,
                pid: item.pid,
                fid: item.fid

            }
            orderApi.editfilmproductstatus(data).then(res => {
                if (res.data.status == 200) {
                    message.success('操作成功')
                } else {
                    message.error(res.data.message)
                }

            }).finally(() => {
                this.searchMart()
            })

        },
        //下载某一个拍场数据
        download(id) {

        }
    },
};
</script>

<style lang="less" scoped>
@import "./../../../assets/less/reset.less";
@import "./../../../assets/less/config.less";

.martDetail {
    padding: 20px;
    overflow: hidden;

    .comon-check-form {
        margin-bottom: 20px;
    }

    .operate {
        &.disa {
            cursor: not-allowed;
            color: #999;

        }

    }


    .footer {
        width: 100%;
        text-align: center;
        margin-top: 16px;

        button {
            margin-right: 16px;
        }
    }
}</style>