
<template>
  <div class="mart">
    <a-form id="mart-form" :form="form" v-bind="formItemLayout">
      <a-form-item>
        <h2>设置直播计划信息</h2>
      </a-form-item>
      <a-form-item label="直播计划名称" v-bind="formItemInputLayout">
        <a-input :maxLength="30" placeholder="请输入" v-decorator="[
          'name',
          {
            rules: [{ required: true, message: '请输入' }]
          },
        ]">
          <a-tooltip slot="suffix" title="计划名称不能超过30个字符">
            <a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
          </a-tooltip>
        </a-input>
      </a-form-item>
      <a-form-item label="直播间名称" has-feedback>
        <a-select v-decorator="[
          'place_id',

          {
            initialValue: '',
            rules: [
              { required: true, message: '请选择或输入搜索' },
            ],
          },
        ]" placeholder="请选择或输入搜索" allowClear showSearch>
          <a-select-option v-for="item in liveRoomList" :value="item.live_room_id" :key="item.live_room_id">{{
            item.live_room_name }} </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="预展时间" has-feedback>
        <a-range-picker v-decorator="[
          'rangeTime', {
            rules: [{ type: 'array', required: true, message: '请选择时间!' }]
          }]" show-time format="YYYY-MM-DD HH:mm" :placeholder="['开始时间', '结束时间']" :locale="locale" />
      </a-form-item>
      <a-form-item label="主播名称" v-bind="formItemInputLayout">
        <a-input placeholder="请输入" v-decorator="[
          'anchor',
          {
            rules: [{ required: true, message: '请输入' }]
          },
        ]" />
      </a-form-item>

      <a-form-item :wrapper-col="{ span: 12, offset: 4 }">

        <a-button type="primary" @click="handleSubmit" class="submitBtn footerBtn"> 保存计划</a-button>
        <a-button @click="handleReset" class="footerBtn"> 重置 </a-button>
        <a-button @click="goBackList" class="footerBtn"> 返回列表 </a-button>

      </a-form-item>

    </a-form>
  </div>
</template>
<script>
import { goodsApi } from "@/api/good";
import { orderApi } from "@/api/order";
import {
  message
} from "ant-design-vue";
import moment from "moment";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import 'dayjs/locale/zh-cn'

export default {
  name: "oneMart",
  data() {
    return {
      locale,
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 10 },
      },
      // id:'',
      editData: {},
      startNumList: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      digitList: [3, 4],
      liveRoomList: []
    };
  },

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "mart-form" });
  },
  mounted() {
    // this.id = this.$route.query.id
    this.getSelects()

  },
  methods: {

    //获取下拉
    getSelects() {
      let params = {
        status: 1,
        page: 1,
      }
      orderApi.listlive(params)
        .then((res) => {
          this.liveRoomList = res.data.data.data;
        });



    },
    //保存
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          const { rangeTime } = values
          let params = {
            ...values,
            s_time: moment(rangeTime[0]).format('YYYY-MM-DD HH:mm:ss'),
            e_time: moment(rangeTime[1]).format('YYYY-MM-DD HH:mm:ss')


          }
          //新增
          orderApi.createfilming(params).then(res => {
            if (res.data.status == 200) {
              message.success('拍场新建成功')
            } else {
              message.error(res.data.message)
            }
          })
        } else {
          console.log(err)
        }
      });
    },
    //返回
    goBackList() {
      this.$router.push({ path: '/retail/martList', query: { date: new Date().getTime() } })
    },

    // 重置
    handleReset(e) {
      this.form.resetFields();
    },


  },
};
</script>

<style lang="less" scoped>
@import "./../../../assets/less/reset.less";
@import "./../../../assets/less/config.less";

.mart {
  padding: 0 30px;
}

.ant-calendar-picker {
  width: 100% !important;
}

//底部按钮
.footerBtn {
  margin-left: 34px;
}

.submitBtn {
  margin-left: 0;
}
</style>