<!--
 * @Descripttion: *
 * @Author: wjj
 * @LastEditors: *
 * @LastEditTime: 2024-10-14 19:35:42
-->
<template>
  <div class="header">
    <h2>欢迎使用古玩网络速拍系统</h2>
    <a-badge dot>
      <a-icon type="bell" :style="{ fontSize: '18px'}"/>
    </a-badge>
    <a-dropdown>
      <a class="ant-dropdown-link" @click="e => e.preventDefault()">
        <div class="user-info">
        <img :src="$cookie.get('logo_url')" alt="">
      <div class="username">
        <span style="margin-right: 10px;" >{{$cookie.get('role_name')}}</span>
        <span>{{$cookie.get('usernameStr')}}</span>
        <a-icon type="caret-down" />
      </div>
    </div> 
      </a>
      <a-menu slot="overlay" @click="onClick">
        <a-menu-item key="1">
          个人信息
        </a-menu-item>
        <!-- <a-menu-item key="2">
          修改密码
        </a-menu-item> -->
        <a-menu-item key="3">
          退出登录
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>
<script>

import publicConst  from '@/utils/public_const'
import axios from "axios";
import { message } from 'ant-design-vue';


export default {
  name: 'Header',
  components: {
  },
  methods: {
    onClick({ key }) {
      console.log(`Click on item ${key}`);
      //退出登录
      if(key === '3'){
        axios.get( publicConst.proxyPath + '/user/logout' ).then((res)=>{
          if(res.status == 200){
            this.$cookie.remove('username')
            this.$cookie.remove('password')
            this.$cookie.remove('userid')
            this.$router.push({path:'/login'})
          }else{
            message.error(res.message)
          }
        })
      } else if(key === '1') {
        this.$router.push({path: '/user/userinfo'})
      }
    },
  }
}
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 70px;
  padding: 10px 30px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    width: 70%;
    font-weight: normal;
  }
  .user-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:rgb(134, 131, 131);
    img {
      width: 38px;
      // height: 38px;
      // border-radius: 100%;
      // border: 1px solid #ccc;
      margin-right: 15px;
    }
    .username {
      span{
        margin-right: 6px;
      }
      
    }
  }
  :global(.anticon-notification) {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 16px;
}

  :global(.ant-dropdown) {
    width: 150px;
  }
  :global(.ant-dropdown .ant-dropdown-menu-item) {
      padding: 6px 30px;
      font-size: 12px;
  }
}
</style>