
<template>
    <div class="comon-list purchase-list">
      <div class="comon-publish ">
        <a-button type="primary" @click="() => onEdit('', 'add')">
          新建打印机
        </a-button>
      </div>
  
      <div style="height: 500px;  margin-top: 30px">
        <a-table :columns="columns" :scroll={x:700,y:500} :data-source="data" :pagination="pagination" :loading="loading"
          @change="onChangTable">
          <template slot="operation" slot-scope="text, record">
            <a-popconfirm title=" 确定删除此打印机吗？
              " ok-text="确定" cancel-text="取消" @confirm="() => onEdit(record, 'delete')">
              <a href="javascript:;" class="operate">
                删除</a>
            </a-popconfirm>
            <a-popconfirm v-if="record.status == 0 || record.status == 3" title=" 确定测试打印机状态吗？
              " ok-text="确定" cancel-text="取消" @confirm="() => onEdit(record, 'test')">
              <a href="javascript:;" class="operate grey">
                打印测试</a>
            </a-popconfirm>
           
            <a href="javascript:;" @click="() => onEdit(record, 'edit')" class="operate">编辑</a>
          </template>
        </a-table>
      </div>
      <a-modal :title="editTitle" :visible="moduleVisible" okText="确定" cancelText="取消" @ok="handleOk"
        @cancel="moduleVisible = false">
        <a-form style="padding: 20px" class="searchArea" :form="editform" :label-col="{ span: 10 }"
          :wrapper-col="{ span: 14 }">
          <a-form-item label="打印机名称" style="width: 360px">
            <a-input v-decorator="['name', { initialValue: activeItem.name }]" placeholder="请输入" />
          </a-form-item>
          <a-form-item label="编号" style="width: 360px">
            <a-input v-decorator="['code', { initialValue: activeItem.code }]" placeholder="请输入" />
          </a-form-item>
          <a-form-item label="所在位置" style="width: 360px">
            <a-input v-decorator="['location', { initialValue: activeItem.location }]" placeholder="请输入" />
          </a-form-item>
          <a-form-item label="联系人" style="width: 360px">
            <a-input v-decorator="['username', { initialValue: activeItem.username }]" placeholder="请输入" />
          </a-form-item>
          <a-form-item label="联系电话" style="width: 360px">
            <a-input v-decorator="['tel', { initialValue: activeItem.tel }]" placeholder="请输入" />
          </a-form-item>
          
          <a-form-item label="型号" style="width: 360px">
            <a-input v-decorator="['model', { initialValue: activeItem.model || '佳博 GP-CH421D' }]" placeholder="请输入" />
          </a-form-item>
          <a-form-item label="是否禁用" style="width: 360px">
            <a-select allowClear v-decorator="['status', { initialValue: activeItem.status || '0' }]" placeholder="请选择">
              <a-select-option v-for=" item  in  statusList " :key="item.value" :value="item.value">
              {{ item.name}}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="店铺" style="width: 360px">
            <a-select allowClear v-decorator="['shop_id', { initialValue: activeItem.status}]" placeholder="请选择">
              <a-select-option v-for=" item  in  shopList " :key="item.id" :value="item.id">
              {{ item.name}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-modal>
  
    </div>
  </template>
    
    
    
  <script>
  import {
    h
  } from "vue";
  
  import { goodsApi } from "@/api/good";
  import {userApi} from "@/api/user"
  import {
    message
  } from "ant-design-vue";
  
  export default {
    name: "PurchaseList",
    data() {
      return {
        moduleVisible: false,
        editTitle: '添加',
        shopList:[],
        statusList: [
            {
                value:'0',
                name:'否'
            },
            {
                value:'1',
                name:'是'
            }
        ],
        activeItem: {},
        data: [],
        columns: [
        // {
        //   title: "序号",
        //   width: '70px',
        //   dataIndex: "index",
        //   customRender: (text, record, index) => `${index + 1}`,
        // },
        {
          title: "名称",
          dataIndex: "name",
          width: '200px',
          scopedSlots: {
            customRender: "name",
          },
        },
        {
          title: "编号",
          dataIndex: "code",
          width: '150px',
          scopedSlots: {
            customRender: "code",
          },
        },
        {
          title: "所在位置",
          dataIndex: "location",
          width: '90px',
          scopedSlots: {
            customRender: "location",
          },
        },
        {
          title: "联系人",
          dataIndex: "username",
          width: '120px',
          scopedSlots: {
            customRender: "username",
          },
        },
        {
          title: "联系人电话",
          dataIndex: "tel",
          width: '120px',
          scopedSlots: {
            customRender: "tel",
          },
        },
      
        {
          title: "设备型号",
          dataIndex: "model",
          width: '200px',
          scopedSlots: {
            customRender: "model",
          },
        },
        {
          title: "店铺",
          dataIndex: "shop_name",
          width: '200px',
          scopedSlots: {
            customRender: "shop_name",
          },
        },
        {
          title: "创建时间",
          dataIndex: "create_date",
          width: '200px',
          scopedSlots: {
            customRender: "create_date",
          },
        },
        {
          title: "是否禁用",
          dataIndex: "status",
          width: '200px',
          scopedSlots: {
            customRender: "status",
          },
          customRender: (value, record) => {
            if (value == 0) {
              return '正常'
            }
            if (value == 1) {
              return '禁用'
            }
            
            return '--'
          }
        },
        {
          title: "打印机状况",
          dataIndex: "use_status",
          width: '200px',
          scopedSlots: {
            customRender: "use_status",
          },
          customRender: (value, record) => {
            if (value == 0) {
              return '离线'
            }
            if (value == 1) {
              return '在线'
            }
            if (value == 2) {
              return '缺纸'
            }
            if (value == 3) {
              return '其他异常'
            }
            if (value == 4) {
              return '过热'
            }
            if (value == 5) {
              return '开盖'
            }
            if (value == 8) {
              return '暂停'
            }
            if (value == 9) {
              return '打印中'
            }
            return '--'
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: '150px',
          fixed: 'right',
          scopedSlots: {
            customRender: "operation",
          },
        },
        ],
        loading: false,
        pagination: {
          total: 0,
          current: 1,
          pageSize: 50
        },
  
  
      };
    },
  
    beforeCreate() {
      if (this.$cookie.get('username')) {
        this.editform = this.$form.createForm(this, {
            name: "validate_edit",
          });
      } else {
        message.warning('请登录')
        this.$router.push({ path: '/login' })
      }
    },
    mounted() {
      this.getshopList()   
      this.searchData();
      message.config({
        top: `100px`,
        duration: 8,
      });
  
    },
    computed: {
  
    },
    methods: {
        //店铺列表
        async getshopList(){
        let data = {
          page: 1,
          pageSize:200
        }
         await userApi.listshop(data).then(res=>{
            if(res.data.status == 200){
              this.shopList = res.data.data.data
            }
            
          })
          
        },
      //获取表格数据
      getTable(data) {
        userApi.listprint(data)
          .then((res) => {
            console.log(res, "resres");
            if (res.data.status == 200 && res.data.data.list.length > 0) {
              this.data = []
              this.pagination.total = +res.data.data.total;
  
              res.data.data.list.map(good => {
                this.data.push({ ...good, key: good.id })
  
              })
            } else {
              this.data = [];
              this.pagination.total = 0;
            }
          })
          .finally((res) => {
  
          });
      },
      //翻页
      onChangTable(page) {
        this.pagination.current = page.current;
        this.searchData()
      },
      //搜索
      searchData() {
        let params = {
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          creator_id: this.$cookie.get('userid'),
          shop_id:this.$cookie.get('userid')
        };
        this.getTable(params);
      },
      //编辑
      onEdit(record, type) {
        this.editType = type
        let url = ''
        let msg = ''
        let params = {
          print_id: record.id
        }
        switch (type) {
          case 'add':
            this.moduleVisible = true
            this.editTitle = '添加'
            this.activeItem = {}
  
            break;
            case 'edit':
            this.moduleVisible = true
            this.editTitle = '编辑'
            this.activeItem = {...record}
            break;
          case 'test':
            params.code = record.code
            url = 'printsendapi'
            msg = '打印机测试结果【正常】'
            break;
          case 'delete':
            url = 'deleteprint'
            msg = '删除打印机成功'
  
            break;
          default:
            break;
        }
  
        userApi[url](params)
          .then((res) => {
            console.log(res, "res");
            if (res.data.status == 200 && !(type == 'test' && res.data.data.code !=0)) {
              
              message.success(msg)
             
            } else {
              message.error(res.data.message)
            }
          })
          .finally((res) => {
            this.searchData()
  
          });
  
  
  
      },
  
  
      handleOk() {
        this.editform.validateFields((err, values) => {
          let params = {
            ...values,
            creator_id: this.$cookie.get('userid'),
            shop_id:this.$cookie.get('userid')
  
          }
          let url = ''
          let msg = ''
          switch (this.editTitle) {
            case '添加':
              url = 'addprint'
              msg = '添加打印机成功'
              break;
            case '编辑':
              params.print_id = this.activeItem.id
              url = 'editprint'
              msg = '编辑打印机成功'
              break;
            default:
              break;
          }
  
         userApi[url](params)
            .then((res) => {
              console.log(res, "resres");
              if (res.data.status == 200) {
                message.success(msg)
              } else {
                message.error(res.data.message)
              }
            })
            .finally((res) => {
              this.moduleVisible = false
              this.activeItem ={}
              this.editform.resetFields();
              this.searchData()
  
            });
  
        })
  
  
      },
  
  
    },
  };
  </script>
    
  <style lang="less" scoped>
  @import "./../../assets/less/reset.less";
  @import "./../../assets/less/config.less";
  
  .operate {
    padding-right: 5px;
  }
  </style>