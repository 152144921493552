<template>
  <div class="publish">
    <a-form
      id="validate-other"
      :form="form"
      v-bind="formItemLayout"
      
    >
      <a-form-item >
        <h2>{{ titleText }}</h2>
      </a-form-item>
      <a-form-item >
        <h3>基本信息</h3>
      </a-form-item>
      <a-form-item label="拍品描述" v-bind="formItemInputLayout">
        <a-textarea
          v-decorator="[
            'details',
            {initialValue:editData.details},
          ]"
          id="desc"
          placeholder="非必填，如果没有则为拍品名称，拍品尺寸，（重量），备注组合而成"
        ></a-textarea>
      </a-form-item>
      <a-form-item label="拍品图片">
        <Upload  ref="upload" />
      </a-form-item>
      <a-form-item label="拍品名称" v-bind="formItemInputLayout">
        <a-input
          placeholder="必填，例如：清 寿山石罗汉摆件"
          v-decorator="[
            'title',
            { 
              initialValue:editData.title,
              rules: [{ required: true, message: '请输入' }] 
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="拍品品相" v-bind="formItemInputLayout">
        <a-textarea
          placeholder="非必填，例如：有冲线等，如没有则为全品"
          v-decorator="[
            'condition',
            {
              initialValue:editData.condition,

            }
          ]"
        />
      </a-form-item>
      <a-form-item label="拍品分类"  v-bind="formItemInputLayout">
        <a-cascader
          v-decorator="[
              'cateArr',
              {
                initialValue:editData.cateArr,
                rules: [
                  { required: true, message: '请选择或输入搜索' },
                ],
              },
            ]"
          placeholder="请选择或输入搜索"
          allowClear 
          :field-names="{ label: 'name', value: 'code', children: 'items' }"
          :options="cateList"
        />
      </a-form-item>

      <a-form-item label="平台编码" v-bind="formItemInputLayout">
        <a-input
          disabled
          placeholder="平台编码"
          v-decorator="[
            'code',
            {
              initialValue:editData.code
            }
          ]"
        />
      </a-form-item>
      <a-form-item label="内部编码" v-bind="formItemInputLayout">
        <a-input
          placeholder="用于商家内部管理所使用的自定义简易编码"
          v-decorator="[
            'sku',
            {
              initialValue:editData.sku
            }
          ]"
        />
      </a-form-item>
      <Standars :form="form" :standarsList="standarsList"  />

      <a-form-item label="材质" v-bind="formItemInputLayout">
        <a-input
          placeholder="非必填，例如：铜"
          v-decorator="[
            'material',
            { 
              initialValue:editData.material,
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="题识" v-bind="formItemInputLayout">
        <a-input
          placeholder="非必填，例如："
          v-decorator="[
            'recognition',
            { 
              initialValue:editData.recognition,
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="创作年代" v-bind="formItemInputLayout">
        <a-input
          placeholder="非必填，例如：清代"
          v-decorator="[
            'age',
            { 
              initialValue:editData.age,
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="作者" v-bind="formItemInputLayout">
        <a-input
          v-decorator="[
            'author',
            { 
              initialValue:editData.author,
            },
          ]"
        />
      </a-form-item>

       <a-form-item label="形制"  v-bind="formItemInputLayout">
        <a-select
          v-decorator="[
            'xingzhi',
            {
              initialValue:editData.xingzhi,
            },
          ]"
          allowClear 
          showSearch
        >
           <a-select-option  v-for="item in xingzhiList" :key="item.id" :value="item.name" >{{item.name}} </a-select-option> 
          
        </a-select>
      </a-form-item>
      
      <a-form-item label="实物细图">
        <Upload ref="uploadDetail"/>
      </a-form-item>

      <a-form-item label="拍品备注" v-bind="formItemInputLayout">
        <a-textarea
           v-decorator="[
            'remark',
            {
              initialValue: editData.remark,
            }
          ]"
        ></a-textarea>
      </a-form-item>
      

     <a-form-item label="拍品库存" v-bind="formItemInputLayout">
        <a-input
          disabled
          v-decorator="[
            'stocks',
            {
              initialValue: 1,
            }
          ]"
        />
      </a-form-item>
      <a-form-item label="货架" v-bind="formItemInputLayout">
        <a-input
          placeholder="10-9"
          v-decorator="[
            'shelves',
            {
              initialValue: editData.shelves,
            }
          ]"
        />
      </a-form-item>
      <a-form-item >
        <h3>价格信息</h3>
      </a-form-item>
     <a-form-item label="代发价" v-bind="formItemInputLayout">
        <a-input
          placeholder="必填，串货价格"
          v-decorator="[
            'mid_price',
            { initialValue:editData.mid_price,
              rules: [{ required: true, message: '请输入' }] },
          ]"
        />
      </a-form-item>
      <a-form-item label="拿货价" v-bind="formItemInputLayout">
        <a-input
          placeholder="仅自己可见，请放心输入"
          v-decorator="[
            'in_price',
            {
              initialValue:editData.in_price
            }
          ]"
        />
      </a-form-item>
      <!-- <a-form-item label="指导价" v-bind="formItemInputLayout">
        <a-input
          placeholder="请输入"
          v-decorator="[
            'guide_price',
            {
              initialValue:editData.guide_price
            }
          ]"
        />
      </a-form-item> -->
      <a-form-item label="售价" v-bind="formItemInputLayout">
        <a-input
          placeholder="请输入"
          @change="changSalePrice"
          v-decorator="[
            'sale_price',
            {
              initialValue:editData.sale_price
            }
          ]"
        />
      </a-form-item>
      <a-form-item label="零售价" v-bind="formItemInputLayout">
        <a-input
          placeholder="请输入"
          v-decorator="[
            'retail_price',
            {
              initialValue:editData.retail_price
            }
          ]"
        />
      </a-form-item>
      
   <a-form-item >
        <h3>委托方信息</h3>
   </a-form-item>
      <a-form-item label="对接人"  v-bind="formItemInputLayout">
          <a-select 
          v-decorator="[
          'to_username',
          {
              initialValue:editData.to_username,
              rules: [
                { required: true, message: '请选择或输入搜索' },
              ],
            },
          ]" 
          placeholder="请选择或输入搜索" 
          disabled>
            <a-select-option  v-for="item in toUsernameList" :key="item.id" :value="item.name" >{{item.name}} </a-select-option> 
          </a-select>
        </a-form-item>
     <a-form-item label="委托方"  v-bind="formItemInputLayout" >
        <a-select
          v-decorator="[
            'from_username',
            {
              initialValue:editData.from_username,
              rules: [
                { required: true, message: '请选择或输入搜索' },
              ],
            },
          ]"
          placeholder="请选择或输入搜索"
          allowClear 
          showSearch
          @change="changefromUsername"
        >
           <a-select-option  v-for="item in fromUsernameList" :key="item.id" :value="item.name" >{{item.name}} </a-select-option> 
          
        </a-select>
      </a-form-item>
      <a-form-item >
          <h3>评分信息</h3>
      </a-form-item>      
      <a-form-item label="排品逻辑"  v-bind="formItemInputLayout">
        <a-select
          v-decorator="[
            'new_type',
            {
              initialValue:editData.new_type || '0',
            },
          ]"
          placeholder="请选择"
          disabled 
        >
          
           <a-select-option  v-for="item in typeList" :key="item.value" :value="item.value" >{{item.name}} </a-select-option> 
         
        </a-select>
      </a-form-item>
      <a-form-item label="赔付等级" v-bind="formItemInputLayout">
        <a-select
          v-decorator="[
            'compen_level',
            {
              initialValue:editData.compen_level || '0',
            },
          ]"
          placeholder="请选择"
          disabled 
        >
          
           <a-select-option  v-for="item in compenLevelList" :key="item.value" :value="item.value" >{{item.name}} </a-select-option> 
         
        </a-select>
      </a-form-item> 
      <a-form-item label="品相等级" v-bind="formItemInputLayout">
        <a-select
          v-decorator="[
            'app_level',
            {
              initialValue:editData.app_level || '0',
            },
          ]"
          placeholder="请选择"
          allowClear 
        >
          
           <a-select-option  v-for="item in appLevelList" :key="item.value" :value="item.value" >{{item.name}} </a-select-option> 
         
        </a-select>
      </a-form-item>
      <a-form-item label="珍稀程度" v-bind="formItemInputLayout">
        <a-select
          v-decorator="[
            'rare_level',
            {
              initialValue:editData.rare_level || '0',
            },
          ]"
          placeholder="请选择"
          allowClear 
        >
          
           <a-select-option  v-for="item in rareLevelList" :key="item.value" :value="item.value" >{{item.name}} </a-select-option> 
         
        </a-select>
      </a-form-item>
      <a-form-item label="完残程度" v-bind="formItemInputLayout">
        <a-select
          v-decorator="[
            'com_level',
            {
              initialValue:editData.com_level || '0',
            },
          ]"
          placeholder="请选择"
          allowClear 
        >
          
           <a-select-option  v-for="item in comLevelList" :key="item.value" :value="item.value" >{{item.name}} </a-select-option> 
         
        </a-select>
      </a-form-item>
       

      <a-form-item :wrapper-col="{ span: 12, offset: 3 }">
        <a-button type="primary" @click="handleSubmit"  class="submitBtn footerBtn"> {{submitText}}</a-button>
        <a-button  v-if="!pid" @click="handleReset"  class="footerBtn"> 重置 </a-button> 
        <a-button  @click="goBackList" class="footerBtn" > 返回列表 </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import Upload from "@/components/UploadImg.vue";
import publicConst  from '@/utils/public_const'
import { goodsApi } from "@/api/good";
import axios, { formToJSON } from "axios";
import Standars from './standars.vue';
import { message } from 'ant-design-vue';


export default {
  name: "publish",
  props:{
  },
  data: () => ({
    formItemLayout: {
      labelCol: { span: 2 },
      wrapperCol: { span: 14 },
    },
    formItemInputLayout: {
      labelCol: { span: 2 },
      wrapperCol: { span: 12 },
    },
    titleText: '拍品操作页',
    submitText:'提交拍品',
    pid:'',
    publishType: 0, // 1-采购单商品同步操作，0-拍品操作
    editData:{},
    cateList:[],
    typeList:[
      {
        name:'未选择',
        value:'0'
      },
      {
        name:'福利款',
        value:'1'
      },
      {
        name:'承接款',
        value:'2'
      },
      {
        name:'利润款',
        value:'3'
      },
      {
        name:'精品款',
        value:'4'
      },
      {
        name:'挂链款',
        value:'5'
      },
    ],
    appLevelList:[
      {
        name:'未选择',
        value:'0'
      },
      {
        name:'极',
        value:'1'
      },
      {
        name:'美',
        value:'2'
      },
      {
        name:'上',
        value:'3'
      },
      {
        name:'中',
        value:'4'
      },
      {
        name:'下',
        value:'5'
      },
    ],
    rareLevelList:[
      {
        name:'未选择',
        value:'0'
      },
      {
        name:'稀',
        value:'1'
      },
      {
        name:'珍',
        value:'2'
      },
      {
        name:'少',
        value:'3'
      },
      {
        name:'普',
        value:'4'
      },
      {
        name:'多',
        value:'5'
      },
    ],
    comLevelList:[
      {
        name:'未选择',
        value:'0'
      },
      {
        name:'92分',
        value:'92'
      },
      {
        name:'90分',
        value:'90'
      },
      {
        name:'87分',
        value:'87'
      },
      {
        name:'85分',
        value:'85'
      },
      {
        name:'82分',
        value:'82'
      },
      {
        name:'80分',
        value:'80'
      },
      {
        name:'77分',
        value:'77'
      },
      {
        name:'75分',
        value:'75'
      },
    ],
    compenLevelList:[
      {
        name:'未选择',
        value:'0'
      },
      {
        name:'Ⅰ',
        value:'1'
      },
      {
        name:'Ⅱ',
        value:'2'
      },
      {
        name:'Ⅲ',
        value:'3'
      },
      {
        name:'Ⅳ',
        value:'4'
      },
      {
        name:'Ⅴ',
        value:'5'
      },
      {
        name:'Ⅵ',
        value:'6'
      },
    ],
    xingzhiList:[],
    fromUsernameList:[],
    toUsernameList:[],
    standarsList:{}
  }),
  components: {
    Upload,
    Standars
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "validate_other" });
  },
  created(){
    
  },
  mounted(){
    const { pid, type, detail_id} = this.$route.query;
    this.pid = pid;
    this.publishType = type;
    this.detail_id = detail_id;

    if( this.publishType*1 === 1) {
      this.submitText = '同步拍品';
      this.titleText = '采购单商品同步';
    }
    this.getCateList()
  },
  methods: {
    //分类-形制-委托人列表
   async getCateList(){
     await goodsApi.getcatelistnew().then(res=>{
        this.cateList = res.data.data
        // console.log('fieldNames', this.cateList)
      })
     await goodsApi.xingzhilist().then(res=>{
        this.xingzhiList = res.data.data
      })
     await goodsApi.gettofromlist().then(res=>{
        this.fromUsernameList = res.data.data.clientsList
        this.toUsernameList = res.data.data.receiverList
      })
      if(this.pid){
        this.getproduct(this.pid)
      } 
    },
    //商品详情
    getproduct(pid){
      let params = {
        pid
      }
      goodsApi.getproduct(params).then(res=>{
        if(res.data.status == 200){
        this.standarsList = JSON.parse(res.data.data.standards)
        console.log(this.standarsList,res.data.data.from_username,this.fromUsernameList,'standarsList')
        this.editData = res.data.data
        this.editData.cateArr = res.data.data.cateArr 
        this.$refs.upload.fileList = JSON.parse(res.data.data.img) 
        this.$refs.uploadDetail.fileList = JSON.parse(res.data.data.img_more)
        this.form.getFieldDecorator('standards_keys', { initialValue: this.standarsList?.length == 0?[0]:[...Array(this.standarsList.standarsName?.length).keys()], preserve: true });
        // debugger
        this.form.setFieldsValue({
          xingzhi:res.data.data.xingzhi?this.xingzhiList.filter(item=>item.id==res.data.data.xingzhi)[0]?.name:'',
          // cate:JSON.parse(res.data.data.cate),
          from_username:this.fromUsernameList.filter(item=>item.id==res.data.data.from_username)[0]?.name,
          to_username:this.setToUsername(this.fromUsernameList.filter(item=>item.id==res.data.data.from_username)[0]?.name),
          
        }) 
            
          }
        }
      )
    },

    changSalePrice(e){
      let v = e.target.value
      console.log(v,'changSalePrice')
      let type = '0'
      let compen = '0'
      if(v<1000){
        type = '1'
      }else if(v<3000 && v>=1000){
        type = '2'
      }else if(v<5000 && v>=3000){
        type = '3'
      }else if(v<8000 && v>=5000){
        type = '4'
      }else{
        type = '5'
      }

      if(v<500){
        compen = '1'
      }else if(v<5000 && v>=500){
        compen = '2'
      }else if(v<10000 && v>=5000){
        compen = '3'
      }else if(v<30000 && v>=10000){
        compen = '4'
      }else if(v<50000 && v>=30000){
        compen = '5'
      }else{
        compen = '6'
      }
      this.form.setFieldsValue({
        new_type:type,
        compen_level:compen
      })

      
    },

    //返回
    goBackList(){
      // this.$router.push({path:'/goods/list',query: {date:new Date().getTime()}})
      this.$router.back()
    },

    // 重置
    handleReset(e){
       this.form.resetFields(
          ['details','title','condition',
          'material','recognition',
          'age','author','xingzhi',
          'sku','desc','mid_price','sale_price','retail_price',
          'in_price','standarsName','standarsWidth',
          'standarsDiameter','standarsCaliber','standarsWeight',
          'standarsThickness','standards_keys',
          'remark','shelves','new_type','compen_level',
          'app_level','rare_level','com_level',
          []]
        );
        this.$refs.upload.fileList = []
        this.$refs.uploadDetail.fileList = []

    },

      changefromUsername(value){
        this.setToUsername(value)
      },
    setToUsername(v){
       let pid = this.fromUsernameList.filter(item=>item.name==v)[0]?.pid
      let hasTo = this.toUsernameList.filter(item=>item.id ==pid)[0]
      let name =''
      if(hasTo){
         name = hasTo.name
      }
      this.form.setFieldsValue({to_username:name})
      return name

    },

    // 提交
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("handleSubmit ", values);
          const { standarsName,standarsWidth,standarsDiameter,standarsCaliber,standarsWeight,standarsThickness } = values
    
          let standards = JSON.stringify({
            standarsName,standarsWidth,standarsDiameter,standarsCaliber,standarsWeight,standarsThickness
          })
          let img = JSON.stringify(this.$refs.upload.fileList)
          let img_more  = JSON.stringify(this.$refs.uploadDetail.fileList)
          // let cateArr = JSON.stringify(values.cateArr)
           let params ={
              ...values,
              // cateArr,
              standards,
              img,
              img_more,
              xingzhi:values.xingzhi?this.xingzhiList.filter(item=>item.name==values.xingzhi)[0]?.id:'',
              cate:values.cateArr[values.cateArr.length-1],
              // cate:JSON.stringify(values.cate),
              to_username:this.toUsernameList.filter(item=>item.name==values.to_username)[0]?.id,
              from_username:this.fromUsernameList.filter(item=>item.name==values.from_username)[0]?.id,
              is_completed:this.editData.is_completed,
              creator_id: this.$cookie.get('userid'),
              shop_id:this.$cookie.get('userid')

            }
          //编辑
          if(this.pid){
             params ={
              ...params,
              pid:this.pid,
              detail_id: this.detail_id,
            }
            // this.$router.push({path:'/goods/list'})
            goodsApi.editproduct(params).then(res=>{
              if(res.data.status == 200){
                if( this.publishType*1 === 1) {
                  message.success('商品同步成功');
                  this.goBackList();
                } else {
                    message.success('拍品编辑成功');
                  this.$router.push({path:'/goods/list',query: {date:new Date().getTime()}});
                }
                
              }
            })
          }else{
            params ={
              ...params
            }
            //新增
            goodsApi.addproduct(params).then(res=>{
              if(res.data.status == 200){
                message.success('拍品新建成功')
              }
            })
          }
        }else{
          console.log(err)
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
h3{
  font-size: 18px !important;
}
#components-form-demo-validate-other .dropbox {
  height: 180px;
  line-height: 1.5;
}
.footerBtn{
    width: 160px;
    height: 52px;
    margin-top: 30px;
    margin-left: 34px;
}
.submitBtn{
    margin-left: -44px;
}

.publish {
  padding: 0 30px;
  width: 1200px;
  #desc {
    resize: none;
    width: 600px;
    height: 60px;
    line-height: 20px;
    padding: 5px 5px;
    overflow-y: scroll;
  }
  :global(.ant-form .ant-form-item) {
    margin-bottom: 10px;
  }
}
</style>
