
<template>
    <div class="comon-list ">
      <div class="comon-publish ">
      <a-button type="primary" @click="$router.push({ path: '/purchase/newReturn' })">
        新建退货单
      </a-button>
    </div>
      <div class="comon-check-form ">
        <a-form style="padding: 20px" class="searchArea" :form="form" layout="inline" :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }">
          <a-form-item label="退货单名称" style="width: 235px">
            <a-input v-decorator="['name']" placeholder="请输入" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="searchPurchase"> 搜索 </a-button>
          </a-form-item>
          <a-form-item>
            <a-button class="resetBtn" @click="resetSearch"> 重置 </a-button>
          </a-form-item>
        </a-form>
      </div>
      <div style="height: 500px;  margin-top: 30px">
        <a-table 
        :columns="columns" 
        :scroll={x:1500,y:500} 
        :data-source="data" 
        :pagination="pagination" 
        :loading="loading"
        @change="onChangTable" 
        >
          <template slot="operation" slot-scope="text, record">
              <a href="javascript:;" @click="() => onDetail(record)" class="operate" >查看</a>
               <a href="javascript:;" class="operate" @click="editRefund(record)" >编辑</a>
              <a href="javascript:;" class="operate"  v-if="record.status*1 !==1 " @click="setrefund(record)">提交</a>
              <a-popconfirm  title="确定要删除该商品" ok-text="删除" cancel-text="取消" @confirm="delReturna(record)">
                   <a href="javascript:;" class="operate" v-if="record.status*1 !==1 ">删除</a>
               </a-popconfirm>
          </template>
        </a-table>
      </div>
      
       <a-modal
        title="退货单列表信息" 
        :visible=" moduleVisible " 
        okText="确定" 
        cancelText="取消" 
        @ok="handleOk()"  
        @cancel="moduleVisible = false"
      >
        <a-form style="padding: 20px" class="searchArea" :form="editform" :label-col=" { span: 5 } "
          :wrapper-col=" { span: 15 } ">
          <a-form-item label="商品名称" >
            <a-input  v-decorator=" ['purchase_price',{initialValue: activeItem.name }] " disabled />
          </a-form-item>
    
          <a-form-item label="备注" style="margin-top: 20px;">
            <a-input v-decorator=" ['remark',{initialValue: activeItem.remark }] " placeholder="请输入" type="textarea" />
          </a-form-item>
        </a-form>
      </a-modal> 
    
    </div>
  </template>
  
  
  
  <script>
     import {orderApi} from "@/api/order"

    import {
      message
    } from "ant-design-vue";
  
    export default {
      name: "PurchaseList",
      data() {
        return {
          data: [],
          columns:[ 
            {title: "编号",
            width: '70px',
            fixed: 'left',
            dataIndex: "refund_id",
            key: "refund_id",
            scopedSlots: {
              customRender: "refund_id",
            }, 
          },
          {
            title: "退货单名称",
            dataIndex: "name",
            key: "name",
            width: '150px',
            scopedSlots: {
              customRender: "name",
            },
          },
          {
            title: "供货商名称",
            dataIndex: "supplier_name",
            key: "supplier_name",
            width: '120px',
            scopedSlots: {
              customRender: "supplier_name",
            },
          },
          {
            title: "数量",
            dataIndex: "refund_quantity",
            key: "refund_quantity",
            width: '90px',
            scopedSlots: {
              customRender: "refund_quantity",
            },
          },
        
          {
            title: "总金额",
            dataIndex: "refund_amount",
            key: "refund_amount",
            width: '150px',
            scopedSlots: {
              customRender: "refund_amount",
            },
          },
           {
            title: "创建时间",
            dataIndex: "refund_time",
            key: "refund_time",
            width: '180px',
            scopedSlots: {
              customRender: "refund_time",
            },
          },
           {
            title: "负责人",
            dataIndex: "creator_name",
            key: '',
            width: '150px',
            scopedSlots: {
              customRender: "creator_name",
            },
          },
          {
            title: "状态",
            dataIndex: "status",
            key: "status",
            width: '70px',
            scopedSlots: {
              customRender: "status",
            },
            customRender: (value,record)=>{
              // 1-已完成， 0-未确认
              if(value == 1){
                return '已完成'
              } else {
                return '未确认'
              }
            }
          },
           {
            title: "备注",
            dataIndex: "remark",
            key: "remark",
            width: '200px',
            scopedSlots: {
              customRender: "remark",
            },
          },
          {
            title: "操作",
            dataIndex: "operation",
            key: "operation",
            width: '200px',
            fixed: 'right',
            scopedSlots: {
              customRender: "operation",
            },
          },
        ],
          loading: false,
          pagination: {
            total: 0,
            current: 1,
            pageSize:50
          },
          editform: this.$form.createForm(this, { name: "editform"}),
          moduleVisible: false,
          purchaseModal:false,
          activeItem: {},
  
        };
      },
      
      beforeCreate() {
        if(this.$cookie.get('username')){
          this.form = this.$form.createForm(this, {
            name: "validate_other",
          });
        }else{
          message.warning('请登录')
          this.$router.push({path:'/login'})
        }
      },
      mounted() {
        this.searchPurchase();
        message.config({
          top: `100px`,
          duration: 8,
        });
        
      },
      computed: {
          
      },
      methods: {
        //重置搜索条件
        resetSearch () {
          this.form.resetFields();
          this.pagination.current = 1
          this.searchPurchase();
        },
        //获取表格数据
        getTable(data) {
          this.loading = true;
          orderApi.listrefund(data)
            .then((res) => {
              // console.log(res, "resres");
              if (res.data.status == 200 && res.data.data.data.length > 0) {
                this.data = []
                this.pagination.total = +res.data.data.total;
                
                res.data.data.data.map(good=>{
                   this.data.push({...good,key:good.refund_id})
                   
                })
              } else {
                this.data = [];
                this.pagination.total = 0;
              }
            })
            .finally((res) => {
              this.loading = false;
            });
        },
        //翻页
        onChangTable(page) {
          this.pagination.current = page.current;
          this.searchPurchase()
        },
        //搜索
        searchPurchase() {
          this.form.validateFields((err, values) => {
            let params = {
              page: this.pagination.current,
              ...values,
            creator_id: this.$cookie.get('userid'),
            shop_id:this.$cookie.get('userid')

            };
            this.getTable(params);
          });
        },
        //查看
        onDetail(itemData) {
          const { refund_id, status} = itemData;
          this.$router.push({
            path: "/purchase/ReturnableGoodsList",
            query: {
              refund_id,
              status
            },
          });
        },

        // 提交
        setrefund(data) {
          const { refund_id } = data;
          const params = {
            refund_id, 
            status: 1
          }
            orderApi.setrefund(params)
              .then(res => {
                  if(res.status === 200) {
                    message.success(res.data.message);
                      this.searchPurchase();
                  }
                })
                .catch(err=> console.err)
          },

        // 删除
        delReturna(data) {
          const { refund_id } = data;
          orderApi.deleterefund({ refund_id })
            .then(res => {
                if(res.status === 200) {
                  message.success(res.data.message);
                  
                    this.searchPurchase();
                }
              })
              .catch(err=> console.err)
        },
        // 编辑
        editRefund(data) {
          this.moduleVisible = true
          const { name, remark, refund_id } = data;
          this.activeItem = {
             name, 
             remark,
             refund_id
          }
        },

        // 编辑提交
        handleOk() {
           const { refund_id } = this.activeItem ;
            this.editform.validateFields((err, values) => {
              let data = {
                ...values,
                refund_id,
              };
              orderApi.editrefund(data)
                .then(res => {
                  if(res.status === 200) {
                    message.success(res.data.message);
                    this.moduleVisible = false;
                     this.searchPurchase();
                  }
                })
                .catch(err=> console.err)
               
            })
        }
      },
    };
  </script>
  
  <style lang="less" scoped>
   @import "./../../assets/less/reset.less";
  @import "./../../assets/less/config.less";
  .operate{
    padding-right: 5px;
  }
  
   
  </style>